<template>
    <b-modal
            :id="modalRef? modalRef : PREFIX + '-modal'"
            hide-footer
            size="lg"
            @hide="resetForm()"
            @shown="initData()"
    >
        <template #modal-title>
            {{$t('label_add_task')}}
        </template>
        <template #default="{ hide }">
            <!-- Form -->
            <validation-observer
                    #default="{ handleSubmit, invalid }"
                    :ref="PREFIX + '_FORM'"
                    tag="form"
                    class="p-1"
            >

                <validation-provider

                        #default="validationProps"
                        :name="$t('label_task_type')"
                        rules="required"
                        slim
                >

                    <b-form-group
                            :label="$t('label_task_type')"
                    >
                        <v-select
                                v-model="itemData.task_type_id"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="taskTypes"
                                :reduce="val => val.id"
                                label="name"
                                :disabled="disableTaskTypes"
                                :clearable="false"
                                :placeholder="$t('label_select')"
                                @option:selected="processTaskTypeChanging()"
                        >
                            <template v-slot:option="option">
                              {{option.translation_index ? $t(option.translation_index) : option.name}}
                            </template>
                            <template v-slot:selected-option="option">
                                {{option.translation_index ? $t(option.translation_index) : option.name}}
                            </template>
                        </v-select>

                        <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider
                        v-if="showAll"
                        class="mt-2"
                        #default="validationProps"
                        :name="$t('label_non-system_task')"
                        slim
                >
                    <b-form-group
                            label=""
                    >
                        <b-form-checkbox
                                id="non-system-task"
                                switch
                                value="free"
                                :unchecked-value="blankItemData.type"
                                v-model="itemData.type"
                                @input="processTaskTypeChanging()"
                        >
                            <label for="non-system-task">{{ $t('label_non-system_task') }}</label>
                        </b-form-checkbox>
                    </b-form-group>
                </validation-provider>

                <validation-provider
                        class="mt-2"
                        #default="validationProps"
                        :name="$t('label_priority')"
                        slim
                >
                    <b-form-group
                            :label="$t('label_priority')"
                    >

                        <!--<b-form-checkbox-->
                                <!--id="task-priority"-->
                                <!--value="1"-->
                                <!--unchecked-value="0"-->
                                <!--v-model="itemData.is_priority"-->
                        <!--&gt;-->
                            <!--<label for="task-priority">{{ $t('label_priority') }}</label>-->
                        <!--</b-form-checkbox>-->
                        <v-select
                                v-model="itemData.priority_id"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="taskPriorities"
                                :reduce="val => val.id"
                                label="name"
                                :placeholder="$t('label_select')"
                        >
                            <template v-slot:option="option">
                                <span class="">{{ $t(option.translation_index)  }}</span>
                            </template>
                            <template v-slot:selected-option="option">
                                <span class="">{{  $t(option.translation_index) }}</span>
                            </template>
                        </v-select>
                    </b-form-group>
                </validation-provider>

                <!--***********INFINITY**************************************************************************************8-->

                <template v-if="itemData.type != 'free' && itemData.type != 'google_calendar'">
                    <validation-provider
                            v-if="itemData.type != 'contract_registry'"
                            #default="validationProps"
                            :name="$t('label_deal_number')"
                            rules="required"
                            slim
                    >
                        <b-form-group :label="$t('label_deal_number')"
                        >

                            <infinity-scroll v-if="isDeal"
                                             v-model="itemData.agreement_id"
                                             selected-prop="agreement_id"
                                             primary-key="deal_id"
                                             :has-duplications="true"
                                             url="/deal"
                                             :placeholder="$t('label_select')"
                                             :default-selection="moduleItem? moduleItem : (showAll? chosenAgreement : false)"
                            >
                                <template #label="{item}">{{$t('label_agreement') + ' ' + item.first_name + ' ' + item.last_name + '(' + (item.number?item.number:item.deal_number) + ')'}}</template>

                            </infinity-scroll>
                            <infinity-scroll v-else
                                             v-model="itemData.agreement_id"
                                             selected-prop="id"
                                             url="/agreement"
                                             @item-selected="checkOrCanBeTrackable($event)"
                                             :placeholder="$t('label_select')"
                                             :default-selection="moduleItem? moduleItem : (showAll? chosenAgreement : false)"
                            >
                                <template #label="{item}">{{$t('label_for') + ' ' + item.first_name + ' ' + item.last_name + '(' +  (item.number?item.number:item.deal_number) + ')'}}</template>

                            </infinity-scroll>
                            <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>

                        </b-form-group>
                    </validation-provider>
                    <validation-provider v-if="itemData.type == 'contract_registry'"
                            #default="validationProps"
                            :name="$t('label_agreement_register')"
                            rules="required"
                            slim
                    >
                        <b-form-group :label="$t('label_agreement_register')"
                        >
                            <!--:default-selection="moduleItem? moduleItem : (showAll? chosenAgreement : false)"-->
                            <infinity-scroll
                                    v-model="itemData.pivot_id"
                                    selected-prop="id_contract"
                                    url="/contractregistry"
                                    :placeholder="$t('label_select')"
                                    :default-selection="chosenPivot"
                            >
                                <template #label="{item}">{{ item.contract_subject }}</template>

                            </infinity-scroll>
                            <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>

                        </b-form-group>
                    </validation-provider>
                </template>

                <!--***********INFINITY**************************************************************************************8-->


                <validation-provider v-if="!showAll && itemData.type == 'agreement'"

                        #default="validationProps"
                        :name="$t('label_task_for_user')"
                        rules="required"
                        slim
                >
                    <b-form-group
                            :label="$t('label_task_for_user')"
                    >
                        <v-select
                                v-model="itemData.executors"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="relatedExecutors"
                                :reduce="val => val.id"
                                :clearable="true"
                                label="name"
                                multiple
                                :placeholder="$t('label_select')"
                                @option:selected="userForSmsReceiving = $event; itemData.sms_recipients = []"
                                @option:deselected="userForSmsReceiving = userForSmsReceiving.deleteByVal('id', $event.id); itemData.sms_recipients = []"
                        >
                            <template v-slot:option="option">
                            <span class="">{{ option.name  }}</span>
                            </template>
                            <template v-slot:selected-option="option">
                            <span class="">{{  option.name }}</span>
                            </template>
                        </v-select>

                        <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>

                    </b-form-group>
                </validation-provider>

                <validation-provider
                        v-else
                        #default="validationProps"
                        :name="$t('label_task_for_user')"
                        :rules="itemData.type != 'google_calendar'? 'required' : ''"
                        slim
                >
                    <b-form-group :label="$t('label_task_for_user')"
                    >
                        <!--:default-sel-item="moduleItem"-->
                        <infinity-scroll
                                v-model="itemData.executors"
                                selected-prop="id"
                                url="/user"
                                :multiple="true"
                                :placeholder="$t('label_select')"
                                :default-selection="chosenExecutors"
                                @option:selected="userForSmsReceiving = $event; itemData.sms_recipients = []"
                                @option:deselected="userForSmsReceiving = userForSmsReceiving.deleteByVal('id', $event.id); itemData.sms_recipients = []"
                        >
                            <template #label="{item}">{{item.name}}</template>

                        </infinity-scroll>

                        <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>

                    </b-form-group>
                </validation-provider>

                <b-row>
                    <b-col cols="12" md="6">
                        <validation-provider

                                #default="validationProps"
                                :name="$t('label_term')"
                                rules="required"
                                class="flex-grow-1"
                                slim
                        >
                            <b-form-group
                                    :label="$t('label_term')"
                                    label-for="meeting_type_id"
                            >

                                <custom-date-picker
                                        :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                                        :locale="$store.state.appConfig.locale"
                                        :placeholder="$t('label_DD-MM-YYYY')"
                                        :value="itemData.deadline_date"

                                        @input="itemData.deadline_date = $event"
                                >
                                    <template #clear-btn="{ vm }"><feather-icon icon="XIcon" size="14"/></template>
                                    <div slot="label">
                                        <feather-icon title="Clear" data-toggle icon="CalendarIcon" size="18"/>
                                    </div>
                                </custom-date-picker>

                                <!--@on-change="(val) => {$emit('update:dateFromFilter', (val && val[0])?$moment(val[0]).format('DD-MM-Y'):null);}"-->

                                <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                    {{ validationProps.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col cols="12" md="6">
                        <validation-provider

                                #default="validationProps"
                                :name="$t('label_time')"
                                rules="required"
                                slim
                        >
                            <b-form-group
                                    :label="$t('label_time')"
                                    label-for="meeting_type_id"
                            >

                                <custom-date-picker
                                        :class="{'date--picker': true , 'time--picker':true, 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                                        :locale="$store.state.appConfig.locale"
                                        placeholder="00:00"
                                        format="HH:mm"
                                        display-format="HH:mm"
                                        v-model="itemData.deadline_minutes"
                                        type="time"
                                        :autoSubmit="true"
                                >
                                    <template v-slot:submit-btn="{ vm, canSubmit, color, submit, lang }">
                                        <button type="button" :disabled="!canSubmit" :style="{ color }" @click="submit"
                                                v-text="$t('label_accept')"/>
                                    </template>
                                    <template #clear-btn="{ vm }"><feather-icon icon="XIcon" size="14"/></template>

                                    <div slot="label">
                                        <feather-icon icon="ClockIcon" size="18"/>
                                    </div>
                                </custom-date-picker>

                                <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                    {{ validationProps.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                </b-row>

                <validation-provider
                        class="mt-2"
                        #default="validationProps"
                        :name="$t('label_syclic_task')"
                        slim

                >
                    <b-form-group
                            label=""
                    >

                        <b-form-checkbox
                                id="syclic-task"
                                value="1"
                                unchecked-value="0"
                                v-model="itemData.is_cyclic"
                                switch
                        >
                            <label for="syclic-task">{{ $t('label_syclic_task') }}</label>
                        </b-form-checkbox>
                    </b-form-group>
                </validation-provider>


                <b-row v-if="itemData.is_cyclic == 1">
                    <b-col cols="12" md="6">
                        <validation-provider

                                #default="validationProps"
                                :name="$t('label_interval')"
                                rules="required"
                                slim
                        >
                            <b-form-group

                                    :label="$t('label_interval')"
                            >
                                <v-select
                                        v-model="itemData.interval_id"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        :options="task_intervals"
                                        :reduce="val => val.id"
                                        :clearable="true"
                                        label="name"
                                        :placeholder="$t('label_select')"
                                >
                                    <template v-slot:option="option">
                                        <span class="">{{ $t(option.translation_index) }}</span>
                                    </template>
                                    <template v-slot:selected-option="option">
                                        <span class="">{{ $t(option.translation_index)}}</span>
                                    </template>
                                </v-select>

                                <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                                    {{ validationProps.errors[0] }}
                                </b-form-invalid-feedback>

                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col cols="12" md="6">
                        <validation-provider

                                #default="validationProps"
                                :name="$t('label_term')"
                                rules="required"
                                class="flex-grow-1"
                                slim
                        >
                            <b-form-group
                                    :label="$t('label_term')"
                                    label-for="meeting_type_id"
                            >

                                <custom-date-picker
                                        :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                                        :locale="$store.state.appConfig.locale"
                                        :placeholder="$t('label_DD-MM-YYYY')"
                                        :value="itemData.cyclic_deadline_at"

                                        @input="itemData.cyclic_deadline_at = $event"
                                >
                                    <template #clear-btn="{ vm }"><feather-icon icon="XIcon" size="14"/></template>
                                    <div slot="label">
                                        <feather-icon title="Clear" data-toggle icon="CalendarIcon" size="18"/>
                                    </div>
                                </custom-date-picker>


                                <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                                    {{ validationProps.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                </b-row>


                <validation-provider
                        class="mt-2"
                        #default="validationProps"
                        :name="$t('label_content')"
                        rules="required"
                        slim
                >
                    <b-form-group
                            :label="$t('label_content')"
                            label-for="meeting_type_id"
                    >
                        <quill-editor

                                required
                                v-model="itemData.task"
                                :options="snowOption"
                        ></quill-editor>

                        <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider
                        class="mt-2"
                        #default="validationProps"
                        :name="$t('label_sync_with_google_calendar_users')"
                        slim
                >
                    <b-form-group
                            label=""
                    >
                        <!--:checked="itemData.google_sync"-->
                        <b-form-checkbox
                                id="t-sync-with-google"
                                value="1"
                                unchecked-value="0"
                                v-model="itemData.google_sync"
                        >
                            <label for="t-sync-with-google">{{ $t('label_sync_with_google_calendar_users') }}</label>
                        </b-form-checkbox>
                    </b-form-group>
                </validation-provider>

                <validation-provider
                        class="mt-2"
                        #default="validationProps"
                        :name="$t('label_track_task_working_time')"
                        slim
                >
                    <b-form-group
                            label=""
                    >
                        <!--:checked="itemData.google_sync"-->
                        <b-form-checkbox
                                :disabled="isTrackableDisabled"
                                id="t-is_trackable"
                                value="1"
                                unchecked-value="0"
                                v-model="itemData.is_trackable"
                        >
                            <label for="t-is_trackable">{{ $t('label_track_task_working_time') }}</label>
                        </b-form-checkbox>
                    </b-form-group>
                </validation-provider>

                <template v-if="itemData.type == 'agreement'">
                    <label class="mb-1">{{$t('label_documents')}}</label>
                    <div v-if="alreadyAssignedDocs && alreadyAssignedDocs.length > 0" class="interacted-list form-group">

                        <b-badge v-for="doc in alreadyAssignedDocs"  variant="light-secondary">
                            <a :href="$base_url+AGREEMENT_PREFIX+'_'+PREFIX+'s_documents/download/' + doc.id + '?hash=' + doc.hash"
                               target="_blank" class="text-success m-l-15 "
                               :title="doc.display_name">{{doc.display_name}}</a> <feather-icon @click="deleteTaskDoc(doc.id)" icon="XCircleIcon"></feather-icon></b-badge>

                    </div>

                    <!--***********INFINITY**************************************************************************************8-->
                    <validation-provider

                            #default="validationProps"
                            :name="$t('label_related_docs')"
                            rules=""
                            slim
                    >
                        <b-form-group :label="$t('label_related_docs')"
                        >

                            <infinity-scroll
                                    :disabled="showAll && !itemData.agreement_id"
                                    :query-params="{agreement_id: (moduleItem?(isDeal?moduleItem.agreement_id:moduleItem.id): itemData.agreement_id), extra_search:'default'}"
                                    selected-prop="id"
                                    :multiple="true"
                                    url="/agreement_documents"
                                    :placeholder="$t('label_select')"
                                    :close-on-select="false"
                                    v-model="itemData.deal_docs"
                                    :default-selection="chosenRelDocs"
                            >
                                <template #label="{item}">{{ item.display_name }}</template>

                            </infinity-scroll>

                            <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>

                        </b-form-group>
                    </validation-provider>
                    <!--***********INFINITY**************************************************************************************8-->

                </template>

                <template v-if="itemData.type == 'free' || itemData.type == 'google_calendar'">
                    <validation-provider
                            class="mt-2"
                            #default="validationProps"
                            :name="$t('label_notify_user_about_task')"
                            slim
                    >
                        <b-form-group
                                label=""
                        >
                            <b-form-checkbox
                                    id="notify_about_task"
                                    value="1"
                                    unchecked-value="0"
                                    v-model="itemData.notify_about_task"
                            >
                                <label for="notify_about_task">{{ $t('label_notify_user_about_task') }}</label>
                            </b-form-checkbox>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            v-if="itemData.notify_about_task == 1"
                            #default="validationProps"
                            :name="$t('label_please_enter_valid_email')"
                            rules="required|email"
                            slim
                    >
                        <b-form-group
                                :label="$t('label_please_enter_valid_email')"

                        >
                            <b-form-input

                                    v-model="itemData.email"
                                    :state="getValidationState(validationProps)"
                                    :placeholder="$t('label_please_enter_valid_email')"
                            />

                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                </template>

                <validation-provider
                        class="mt-2"
                        #default="validationProps"
                        :name="$t('label_notify_user_via_sms')"
                        slim
                >
                    <b-form-group
                            label=""
                    >
                        <b-form-checkbox
                                id="notify_via_sms"
                                value="1"
                                unchecked-value="0"
                                v-model="itemData.notify_via_sms"
                        >
                            <label for="notify_via_sms">{{ $t('label_notify_user_via_sms') }}</label>
                        </b-form-checkbox>
                    </b-form-group>
                </validation-provider>

                <div v-show="itemData.notify_via_sms == 1">

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_recipients')"
                            rules="required-if-empty:@written_recipients"
                            slim
                            :skip-if-empty="itemData.notify_via_sms == 1 ? false : true"
                            vid="chosen_recipients"
                    >
                        <b-form-group
                                :label="$t('label_recipients')"
                        >
                            <v-select
                                    v-model="itemData.sms_recipients"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="userForSmsReceiving"
                                    :reduce="val => val.id"
                                    :clearable="true"
                                    label="name"
                                    multiple
                                    :placeholder="$t('label_select')"
                            >
                                <template v-slot:option="option">
                                    <span class="">{{ option.name  }}</span>
                                </template>
                                <template v-slot:selected-option="option">
                                    <span class="">{{  option.name }}</span>
                                </template>
                                <template v-slot:no-options="option">
                                    {{$t('label_no_items')}}
                                </template>
                            </v-select>

                            <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>

                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_please_enter_valid_phone')"
                            :rules="(itemData.notify_via_sms == 1 ? 'required-if-empty:@chosen_recipients' : '')"
                            slim
                            :skip-if-empty="itemData.notify_via_sms == 1 ? false : true"
                            vid="written_recipients"
                    >
                        <b-form-group
                                :label="$t('label_please_enter_valid_phone')"

                        >
                            <b-form-input

                                    v-model="itemData.phone_for_sms"
                                    :state="getValidationState(validationProps)"
                                    :placeholder="$t('label_phone')"
                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>


                    <validation-provider
                            class="mt-2"
                            #default="validationProps"
                            :name="$t('label_sms_pattern')"
                            :rules="(itemData.notify_via_sms == 1 ? 'required' : '')"
                            slim
                    >
                        <b-form-group
                                :label="$t('label_sms_pattern')"
                        >
                            <infinity-scroll
                                    selected-prop="id"
                                    :multiple="false"
                                    url="/sms_patterns"
                                    :placeholder="$t('label_select')"
                                    :close-on-select="false"
                                    v-model="itemData.sms_pattern_id"
                                    :default-selection="chosenPattern"
                            >
                                <template #label="{item}">{{ item.pattern_name }}</template>

                            </infinity-scroll>

                            <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>

                        </b-form-group>
                    </validation-provider>

                </div>


                <validation-provider
                        class="mt-2"
                        #default="validationProps"
                        :name="$t('label_to_ocr')"
                        slim
                >
                    <b-form-group
                            label=""
                    >
                        <!--:checked="itemData.google_sync"-->
                        <b-form-checkbox
                                id="task-i-ocr"
                                value="1"
                                unchecked-value="0"
                                v-model="itemData.ocr"
                        >
                            <label for="task-i-ocr">{{ $t('label_to_ocr') }}</label>
                        </b-form-checkbox>
                    </b-form-group>
                </validation-provider>

                <validation-provider
                        class="mt-2"
                        :name="$t('label_document')"
                        slim
                        #default="validationProps"

                >

                <file-input

                        v-model="new_task_documents"
                        class="file--uploader drop-block mb-3"
                        :max-files-count='10'
                        :required="true"
                        :multiple="true"
                        allowed-extensions="*"
                        :max-file-size='15'
                        :url="''"
                >
                    <template #uploadBody>
                        <feather-icon class="mr-1" data-toggle icon="DownloadCloudIcon" size="24"/>
                        <!--<img :src="require('@/assets/images/upload.svg')" class="mr-1">-->
                      {{$t('label_add_file')}}
                    </template>

                    <template #errorBug="fileErrors">

                        <span v-if="validationProps.failedRules.length > 0 || fileErrors.errors.length > 0">{{validationProps.failedRules.length  > 0 ?  validationProps.errors[0] : fileErrors.errors[0] }}</span>
                        <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </template>
                </file-input>

                </validation-provider>

                <b-progress v-if="percentsHttpRequestDone" :value="percentsHttpRequestDone" max="100" show-progress animated></b-progress>

                <div class="d-flex justify-content-end mt-2">

                    <button class=" btn btn-default" @click.prevent="$bvModal.hide(modalRef? modalRef : PREFIX + '-modal')">
                        {{$t('label_close')}}
                    </button>

                    <button type="submit" @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)"
                            class="btn btn-primary waves-effect waves-light">{{(action == 'editing')?$t('label_edit'):$t('label_add')}}
                    </button>
                </div>

            </validation-observer>


        </template>
    </b-modal>
</template>

<script>
    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import {requiredAtLeastOne} from '@validations'
    import { quillEditor } from 'vue-quill-editor'
    import {
        BFormTextarea,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BInputGroupPrepend,
        BInputGroupAppend,
        BInputGroup,
        BFormCheckbox,
        BBadge,
        BProgress
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import  fileInput from '@/views/components/fileUploadInput'
    import  infinityScroll from '@/views/components/infinityScroll'

    import {serialize} from 'object-to-formdata';

    import { AGREEMENT_PREFIX, DEAL_PREFIX, TASK_PREFIX as PREFIX, CONTRACT_REG_PREFIX} from './../moduleHelper'

    export default {
        components: {
            quillEditor,
            BFormGroup,
            BFormInput,
            BFormInvalidFeedback,
            BFormTextarea,
            BInputGroup,
            BInputGroupPrepend,
            BInputGroupAppend,
            BFormCheckbox,
            BBadge,
            BProgress,
            ValidationProvider,
            ValidationObserver,
            vSelect,
            fileInput,
            infinityScroll,

        },
        props:['moduleItem', 'editedItem',
            'isDeal', 'showAll', 'modalRef', 'taskType'],
        data() {
            return {

                AGREEMENT_PREFIX,
                CONTRACT_REG_PREFIX,
                DEAL_PREFIX,
                PREFIX,

                checkboxes: [],

                action: 'adding',

                blankItemData: {
                    id: 0,
                    task_type_id: null,
                    priority_id: 0,
                    agreement_id: null,
                    type: '', // agreement / free / contract_registry / google_calendar
                    deadline_minutes: '',
                    deadline_date: '',
                    is_cyclic: 0,
                    interval_id: null,
                    cyclic_deadline_at: null,
                    task: '',
                    pivot_id: null,
                    google_sync: 1,
                    ocr:0,
                    deal_docs:[],
                    executors:  [],

                    sms_recipients: [],
                    notify_about_task:0,
                    notify_via_sms:0,
                    phone_for_sms: '',
                    sms_pattern_id: null,
                    email:'',
                    is_trackable: 0

                },

                new_task_documents:[],

                itemData: {},

                relatedAgreements: [],
                relatedExecutors:[],
                taskTypes: [],
                task_intervals: [],

                alreadyAssignedDocs:[],

                chosenAgreement: false,
                chosenExecutors:[],
                chosenRelDocs:false,
                chosenPivot: false,
                chosenPattern: false,

                isTrackableDisabled: true,

                disableTaskTypes: false,

                agrmRegTaskTypes: [],
                agrmTaskTypes: [],

                userForSmsReceiving: [],

                auth_user: false,

                snowOption: {
                    theme: 'snow',
                    "modules": {
                        "toolbar": false
                    }
                },
                taskPriorities: []
            }
        },

        methods: {
            initData() {
                this.getRelatedExecutors(this.moduleItem);

                if(this.taskType){
                    this.blankItemData.type = this.taskType;
                } else if(this.editedItem){
                    this.blankItemData.type = this.editedItem.type;
                } else {
                    this.blankItemData.type = 'agreement';
                }

                let item = Object.assign({}, this.blankItemData);

                if (this.editedItem) {

                    this.action = 'editing';

                    item.id = this.editedItem.id;
                    for (let prop in item) {
                        if (this.editedItem.hasOwnProperty(prop)) {
                            item[prop] = this.editedItem[prop];
                        }
                    }

                    this.alreadyAssignedDocs = this.editedItem.related_docs;

                        if(item.type == 'agreement' && this.editedItem.agreement) {
                            this.chosenAgreement = this.editedItem.agreement;
                            // console.log('d',this.chosenAgreement, item.agreement_id);
                        } else if(item.type == 'contract_registry' && this.editedItem.contract_registry) {
                            this.chosenPivot = this.editedItem.contract_registry;
                        }


                    this.chosenExecutors = this.editedItem.executors;
                    item.executors = this.chosenExecutors.pluck('id');

                    if(this.editedItem.sms_pattern) {
                        this.chosenPattern = this.editedItem.sms_pattern;
                    }

                    item.deadline_date = formatDate(this.editedItem.deadline_at, 'DD/MM/YYYY', 'unix');
                    item.deadline_minutes = formatDate(this.editedItem.deadline_at, 'HH:mm', 'unix');
                    item.cyclic_deadline_at = formatDate(this.editedItem.deadline_at, 'DD/MM/YYYY', 'unix');

                } else {

                    this.action = 'adding';
                    if(this.taskType == 'contract_registry') {
                        this.chosenPivot = this.moduleItem;
                        item.pivot_id = this.moduleItem.id_contract;
                    } else {
                        this.chosenAgreement = this.moduleItem? this.moduleItem : false;
                        if(this.chosenAgreement)
                        this.checkOrCanBeTrackable(this.chosenAgreement);
                    }

                }

                if(item.type == 'contract_registry') {
                    item.task_type_id = this.agrmRegTaskTypes[0].id;
                    this.disableTaskTypes = true;
                } else {
                    this.disableTaskTypes = false;
                }

                this.itemData = item;


            },
            resetForm() {
                this.chosenAgreement = false;
                this.chosenExecutors = [];
                this.chosenRelDocs = false;
                this.new_task_documents = [];
                this.alreadyAssignedDocs = [];
                this.relatedExecutors = [];
                this.itemData = Object.assign({}, this.blankItemData);
                this.$refs[this.PREFIX + '_FORM'].reset();
            },
            onSubmit() {

                let formData = new FormData();

                if(this.itemData.notify_via_sms != 1) {
                    this.itemData.sms_recipients = [];
                    this.itemData.phone_for_sms = '';
                }

                formData = serialize(this.itemData, {}, formData);

                formData.append('deadline_at', this.itemData.cyclic_deadline_at);

                if (this.new_task_documents.length > 0) {
                    for(let i = 0; i< this.new_task_documents.length; i++){
                        formData.append('task_document[]', this.new_task_documents[i]);
                    }
                }

                if (this.action == 'adding') {
                    this.async('post', '/'+this.AGREEMENT_PREFIX+'_' + this.PREFIX + 's', formData, function (resp) { //this.itemData.contact_id
                        this.$emit('item-added', {});
                        this.$bvModal.hide(this.modalRef? this.modalRef : this.PREFIX + '-modal');
                    }, false,  {headers:{'Content-Type': 'multipart/form-data'}, showProgress:true});

                } else {
                    this.async('put', '/'+this.AGREEMENT_PREFIX+'_' + this.PREFIX + 's/' + this.itemData.id, formData, function (resp) {
                        this.$emit('item-edited', this.itemData);
                        this.$bvModal.hide(this.modalRef? this.modalRef : this.PREFIX + '-modal');
                    }, false,  {headers:{'Content-Type': 'multipart/form-data'}, showProgress:true});

                }

            },
            deleteTaskDoc(id){

                this.confirmDeleting((result) => {

                    this.async('delete', '/'+this.AGREEMENT_PREFIX+'_documents/' + id, {params: {}}, function (resp) {
                        this.alreadyAssignedDocs =   this.alreadyAssignedDocs.filter((obj) => obj.id != id);
                    });
                })
            },
            getRelatedExecutors(moduleItem){
                if(moduleItem && moduleItem.id){

                    this.blankItemData['agreement_id'] = this.isDeal ? moduleItem.agreement_id : moduleItem.id;

                    this.async('get', this.AGREEMENT_PREFIX+'/api_getAgreementExecutors/' + (this.isDeal ? moduleItem.agreement_id : moduleItem.id), {}, function(res){
                        let relatedExecutors = res.data;
                        if(!relatedExecutors.findWhere('id',this.auth_user.id)) {
                            this.relatedExecutors = res.data.concat(this.auth_user);
                        } else {
                            this.relatedExecutors = res.data;
                        }

                        // console.log('this.relatedExecutors', this.relatedExecutors);
                    });
                }
            },

            checkOrCanBeTrackable(agreeement){
                if(!agreeement) return;
                if(this.isDeal || agreeement.is_deal){
                    this.isTrackableDisabled = false;
                } else {
                    this.isTrackableDisabled = true;
                }
            },
            processTaskTypeChanging(){

                let task_type_id = this.itemData.task_type_id;

                if(this.itemData.type == 'free') return;

                // let itemTypeObj = false;
                let type = false;

                let agreementType =  this.taskTypes.findWhere('id', task_type_id);

                if(agreementType) {
                    if(agreementType.name_alias == 'google_calendar_event') {
                        type = 'google_calendar';
                    } else {
                        type = 'agreement';
                    }
                } else {
                    let contrRegisterType = this.agrmRegTaskTypes.findWhere('id', task_type_id);
                    if(contrRegisterType) {
                        type = 'contract_registry';
                    }
                }

                if(type) {
                    this.itemData.type = type;
                } else {
                    this.itemData.type = this.blankItemData.type;
                }
            }
        },

        created() {
            this.auth_user = this.authUser();
            this.async('get', '/select_options', {params:{options:['agrm_task_types', 'task_intervals', 'registry_agrm_task_types', 'task_priorities']}}, function(res){

                this.agrmRegTaskTypes = res.data.registry_agrm_task_types;
                this.agrmTaskTypes = res.data.agrm_task_types;
                this.taskPriorities = res.data.task_priorities;

                if(this.showAll) {
                    this.taskTypes = this.agrmTaskTypes.concat(this.agrmRegTaskTypes);
                } else if(this.taskType == 'contract_registry') {
                    this.taskTypes = this.agrmRegTaskTypes;
                } else {
                    this.taskTypes = this.agrmTaskTypes;
                }

                this.task_intervals = res.data.task_intervals;
                // this.envelopeTemplates = res.data.envelope_templates;

            });
        }
    }
</script>