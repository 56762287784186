<template>
    <!--static-->
    <b-modal
            :id="PREFIX + '-modal'"
            hide-footer
            @hide="resetForm()"
            @shown="$emit('modalShown');initData()"


    >
        <template #modal-title>
            {{$t('label_add_payment_from_client')}}
        </template>

        <template #default="{ hide }">

        <validation-observer
                tag="form"
                class="p-1"
                #default="{handleSubmit, reset, invalid}"
                :ref="PREFIX + '_FORM'"

        >
            <validation-provider

                    #default="validationProps"
                    :name="$t('label_table_name')"
                    rules="required"
                    slim
            >
                <b-form-group
                        :label="$t('label_table_name')"
                >
                    <v-select v-if="receivablesOptions !== false"
                            v-model="itemData.due_law_table_id"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="receivablesOptions"
                            :reduce="val => val.id"
                            :clearable="true"
                            label="name"

                            :placeholder="$t('label_select')"
                    >
                        <template v-slot:option="option">
                            <span class="">{{ '#'+formatDate(option.created_at,'DD.MM.YYYY', 'unix')  }}</span>
                        </template>
                        <template v-slot:selected-option="option">
                            <span class="">{{  '#'+formatDate(option.created_at,'DD.MM.YYYY', 'unix') }}</span>
                        </template>
                    </v-select>
                    <infinity-scroll v-else
                            v-model="itemData.due_law_table_id"
                            :multiple="false"
                            primary-key="id"
                            url="/agreement_receivables"
                            selectedProp="id"
                            :placeholder="$t('label_select')"
                             :default-selection="chosenReceivables"
                    >
                        <template #label="{item}">{{'#' + formatDate(item.created_at,'DD.MM.YYYY' ,'unix' )  }}</template>

                    </infinity-scroll>

                    <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                        {{ validationProps.errors[0] }}
                    </b-form-invalid-feedback>

                </b-form-group>
            </validation-provider>

                <validation-provider
                        #default="validationProps"
                        :name="$t('label_payment_brutto_add')"
                        rules="required|price"
                        slim
                >
                    <b-form-group
                            :label="$t('label_payment_brutto_add')"
                    >
                        <b-form-input
                                class="price-input"
                                required
                                v-model="itemData.gross_amount"
                                :state="getValidationState(validationProps)"
                                :placeholder="$t('label_payment_brutto_add')"
                        />

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>


            <validation-provider
                    class="mt-2"
                    #default="validationProps"
                    :name="$t('label_to_ocr')"
                    slim
            >
                <b-form-group
                        label=""
                        label-for="meeting_type_id"
                >
                    <!--:checked="itemData.google_sync"-->
                    <b-form-checkbox
                            id="dont-calculate-provision"
                            value="1"
                            unchecked-value="0"
                            v-model="itemData.dont_calculate_provision"

                    >
                        <label for="dont-calculate-provision">{{ $t('label_dont_calculate_provision') }}</label>
                    </b-form-checkbox>
                </b-form-group>
            </validation-provider>

            <validation-provider

                            #default="validationProps"
                            :name="$t('label_date_of_payment_amount')"
                            rules="required"
                            class="mr-1 flex-grow-1"
                            slim
                    >
                        <b-form-group :label="$t('label_date_of_payment_amount')">

                            <custom-date-picker
                                    :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                                    :locale="$store.state.appConfig.locale"
                                    :placeholder="$t('label_DD-MM-YYYY')"
                                    :value="itemData.payment_date_at"

                                    @input="itemData.payment_date_at = $event"
                            >
                                <template #clear-btn="{ vm }"><feather-icon icon="XIcon" size="14"/></template>
                                <div slot="label">
                                    <feather-icon title="Clear" data-toggle icon="CalendarIcon" size="18"/>
                                </div>
                            </custom-date-picker>

                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>


            <validation-provider
                    class="mt-2"
                    #default="validationProps"
                    :name="$t('label_to_ocr')"
                    slim
            >
                <b-form-group
                        label=""
                >
                    <!--:checked="itemData.google_sync"-->
                    <b-form-checkbox
                            id="c-payment-ocr"
                            value="1"
                            unchecked-value="0"
                            v-model="itemData.ocr"

                    >
                        <label for="c-payment-ocr">{{ $t('label_to_ocr') }}</label>
                    </b-form-checkbox>
                </b-form-group>
            </validation-provider>

            <validation-provider
                    class="mt-2"
                    :name="$t('label_document')"
                    slim
                    #default="validationProps"

            >

                <file-input

                        v-model="document"
                        class="file--uploader drop-block mb-3"
                        :max-files-count='10'
                        :required="true"
                        allowed-extensions='*'
                        :max-file-size='15'
                        :url="''"
                >
                    <template #uploadBody>
                        <feather-icon class="mr-1" data-toggle icon="DownloadCloudIcon" size="24"/>
                        <!--<img :src="require('@/assets/images/upload.svg')" class="mr-1">-->
                        {{$t('label_add_file')}}
                    </template>

                    <template #errorBug="fileErrors">

                        <span v-if="validationProps.failedRules.length > 0 || fileErrors.errors.length > 0">{{validationProps.failedRules.length  > 0 ?  validationProps.errors[0] : fileErrors.errors[0] }}</span>
                        <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </template>
                </file-input>


            </validation-provider>

            <b-progress class="progress-form-control" v-if="percentsHttpRequestDone" :value="percentsHttpRequestDone" max="100" show-progress animated></b-progress>

            <div class="d-flex justify-content-end mt-2">
                <button @click.prevent="$bvModal.hide(PREFIX + '-modal')" type="reset" class=" btn btn-default">{{$t('label_close')}}</button>

                <button @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)" type="submit"
                        class="btn btn-primary waves-effect waves-light">{{(action == 'editing')?$t('label_save'):$t('label_submit')}}</button>
            </div>

        </validation-observer>

        </template>

    </b-modal>
</template>


<script>
    import { ValidationProvider, ValidationObserver} from 'vee-validate'

    import {
         BFormTextarea,
         BFormGroup, BFormInput, BFormInvalidFeedback, BInputGroupAppend, BInputGroup, BFormCheckbox, BInputGroupPrepend,BProgress,
    } from 'bootstrap-vue'
    import  fileInput from '@/views/components/fileUploadInput'
    import infinityScroll from '@/views/components/infinityScroll'
    import vSelect from 'vue-select'
    import {serialize} from 'object-to-formdata';
    import {  AGREEMENT_PREFIX, DEAL_PREFIX, PAYMENT_PREFIX, CLIENT_PAYMENT_PREFIX as PREFIX} from './../moduleHelper'

    export default {
        components: {

             BFormGroup, BFormInput, BFormInvalidFeedback, BFormTextarea, BInputGroup, BInputGroupAppend, BFormCheckbox, BInputGroupPrepend, BProgress,

            ValidationProvider,
            ValidationObserver,
            infinityScroll,
            fileInput,
            vSelect
        },
        props:['moduleItem','editedItem', 'receivablesOptions' ,'payForDueLaw', 'isDeal'],
        data() {
            return {
                AGREEMENT_PREFIX,
                DEAL_PREFIX,
                PAYMENT_PREFIX,
                MODULE_PREFIX:'',
                PREFIX,

                action: 'adding',

                blankItemData: {

                    due_law_table_id:0,
                    gross_amount: '',
                    dont_calculate_provision: 1,
                    payment_date_at: null,
                    ocr: 0,
                },
                document:[],
                itemData: {},

                chosenReceivables: false
            }
        },

        methods: {
            initData(){

                if(this.payForDueLaw){

                    let dueLawTable = {};
                    dueLawTable.id = this.payForDueLaw.id;
                    dueLawTable.created_at = this.payForDueLaw.created_at;
                    this.chosenReceivables = dueLawTable;

                }

                if(this.editedItem){
                    this.action = 'editing';

                    let item = Object.assign({},this.blankItemData);
                    item.id = this.editedItem.id;
                    for (let prop in item) {
                        if (this.editedItem.hasOwnProperty(prop)) {
                            item[prop] = this.editedItem[prop];
                        }
                    }

                    item.payment_date_at = formatDate(item.effective_to_at, 'DD/MM/YYYY', 'unix');

                    this.itemData = item ;
                } else {
                    this.action = 'adding';

                    this.itemData =  Object.assign({},this.blankItemData);

                    if(this.payForDueLaw) {
                        this.itemData.due_law_table_id = this.payForDueLaw.id;
                        this.itemData.agreement_id = this.payForDueLaw.agreement_id;
                    } else {
                        this.itemData[this.AGREEMENT_PREFIX+'_id'] = this.isDeal? this.moduleItem.agreement_id : this.moduleItem.id;
                    }

                }
            },
            resetForm() {
                this.itemData = Object.assign({},this.blankItemData);
                this.document = [];
                this.$refs[this.PREFIX + '_FORM'].reset();
            },
            onSubmit(){

                let formData = new FormData();
                this.itemData.gross_amount = this.itemData.gross_amount.formatPriceToNumber();
                formData = serialize(this.itemData, {}, formData);

                if (this.document.length > 0) {
                    for(let i = 0; i< this.document.length; i++){
                        formData.append('document[]', this.document[i]);
                    }
                }
                if (this.action == 'adding') {
                    this.async('post', '/' + this.AGREEMENT_PREFIX + '_' + this.PAYMENT_PREFIX + 's/api_addDueLawRow', formData, function (resp) {
                        this.$emit('item-added', {});
                        this.$bvModal.hide(this.PREFIX + '-modal');
                    }, false,  {headers:{'Content-Type': 'multipart/form-data'}, showProgress:true});

                } else {

                }

            }
        },
        created(){
            this.MODULE_PREFIX = this.isDeal ? this.DEAL_PREFIX : this.AGREEMENT_PREFIX;
        }


    }
</script>