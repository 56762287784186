<template>
    <v-select

            :options="selectData"
            :filterable="false"
            @open="onOpen"
            @close="onClose"
            :close-on-select="closeOnSelect !== false"
            @search=" q => {search = q}"
            :reduce="val => selectedProp? val[selectedProp] : val"
            :value="selection"
            label="label"
            :multiple="multiple"
            :disabled="disabled"
            :loading="getting_data"
            :get-option-key="getOptionKey"
            :dropdown-should-open="dropdownShouldOpen"
            :placeholder="placeholder"
            @option:selecting="disableWatchSearch = true;"
            @option:selected="$emit('option:selected', $event)"
            @option:deselected="$emit('option:deselected', $event)"
            @input="$emit('input', $event);$emit('item-selected', (selectedProp? selectData.findWhere(selectedProp,$event):$event))"
    >

        <template #list-footer>
            <li ref="load" class="loader" v-show="hasNextPage">
                {{$t('label_loading')}}...
            </li>
        </template>
        <template v-slot:option="option">
            <!--<span :class="option.icon"></span>-->
            <slot name="label" :item="option"><span class="">option</span></slot>
        </template>
        <template v-slot:selected-option="option">
            <!--<span :class="option.icon"></span>-->
            <slot name="label" :item="option"><span class="">selected option</span></slot>
        </template>

        <template v-slot:no-options="option">
            <span v-if="!option.loading && !option.firstInit">{{$t('label_no_items')}}</span>
            <span v-else>{{$t('label_loading')}}...</span>

        </template>

    </v-select>
</template>

<script>
    import vSelect from 'vue-select'

    export default {
        components: {
            vSelect
        },
        model: {
            prop: 'selection',
            event: 'input'
        },
        props: [
            'url',
            'selectedProp',
            'placeholder',
            'selection',
            'defaultSelection',
            'queryParams',
            'multiple',
            'disabled',
            'refresh',
            'primaryKey',
            'hasDuplications',
            'closeOnSelect',
        ],

        name: "InfiniteScroll",

        data: () => ({
            total: 0,
            selectData: [],
            observer: null,
            limit: 10,
            page: 1,
            search: '',
            getting_data: false,
            disableWatchSearch: false,
            chosenItems: [],
            firstInit: true
        }),
        created() {

            if (this.defaultSelection) {

                if (this.multiple) {

                    if (this.defaultSelection.length > 0)
                        this.chosenItems = JSON.parse(JSON.stringify(this.defaultSelection));

                } else {

                    this.chosenItems = [JSON.parse(JSON.stringify(this.defaultSelection))];
                }

                this.selectData = this.chosenItems;
            }

        },
        mounted() {
            this.observer = new IntersectionObserver(this.infiniteScroll, {threshold: 0});
        },
        computed: {
            hasNextPage() {
                return this.selectData.length < this.total;
            },
        },
        watch: {
            defaultSelection: function (newVal) {

                if (this.multiple) {

                    if (this.defaultSelection.length > 0) {
                        this.chosenItems = this.selectData = JSON.parse(JSON.stringify(this.defaultSelection));
                    }

                } else {
                    this.chosenItems = this.selectData = [JSON.parse(JSON.stringify(this.defaultSelection))];
                }
            },

            search: function (newVal) {

                if (!this.disableWatchSearch) {
                    this.getData('fresh');
                } else {
                    this.disableWatchSearch = false;
                }
            },

            refresh: function (newVal) {
                if (newVal) {
                    this.getData('fresh');
                }
                this.$emit('update:refresh', false);
            },
        },
        methods: {

            async onOpen() {
                await this.getData(true);
                if (this.hasNextPage) {

                    await this.$nextTick();

                    this.observer.observe(this.$refs.load)
                }

            },
            onClose() {
                this.observer.disconnect();
            },
            async infiniteScroll([{isIntersecting, target}]) {

                if (isIntersecting) {
                    const ul = target.offsetParent; //getting closest parent with position relative/absolute
                    const scrollTop = target.offsetParent.scrollTop;//( how match pixels was scrolled from top) how match invisible behind parent

                    await this.getData();
                    await this.$nextTick();
                    ul.scrollTop = scrollTop;
                    this.observer.disconnect();
                    if (this.hasNextPage) {
                        this.observer.observe(this.$refs.load)
                    }
                }
            },
            getData(fresh) {

                if (this.getting_data) {
                    return;
                } else {
                    this.getting_data = true;
                }

                if (fresh) {
                    this.page = 1;
                    this.total = 0;
                    this.selectData = this.chosenItems;

                }

                let params = {
                    length: this.limit,
                    start: (this.page - 1) * this.limit,
                    search: this.search
                };

                if (this.queryParams) {
                    params = Object.assign(params, this.queryParams);
                }

                return new Promise((resolve, reject) => {
                    this.async('get', this.url, {params: params}, function (resp) {

                        if(this.primaryKey){
                            let chosenProps = this.chosenItems.pluck(this.primaryKey);
                            resp.data.items = resp.data.items.filter((obj) => {
                                return !chosenProps.includes(obj[this.primaryKey])
                            });
                        } else {
                            let chosenProps = this.chosenItems.pluck(this.selectedProp);
                            resp.data.items = resp.data.items.filter((obj) => {
                                return !chosenProps.includes(obj[this.selectedProp])
                            });
                        }

                        this.selectData = this.selectData.concat(resp.data.items);

                        this.page += 1;
                        this.total = parseInt(resp.data.total);

                        this.getting_data = false;
                        this.firstInit = false;
                        return resolve(resp);

                    });
                });
            },


            // search: _.debounce((loading, search, vm) => {
            //     fetch(
            //         `https://api.github.com/search/repositories?q=${escape(search)}`
            //     ).then(res => {
            //         res.json().then(json => (vm.options = json.items));
            //         loading(false);
            //     });
            // }, 350)
            dropdownShouldOpen({noDrop, open, mutableLoading}) { //for мгу-select v 3.12.0+

                if (this.getting_data) return true;
                return noDrop ? false : open && !mutableLoading;

            },
            getOptionKey(option) {
                if (typeof option === 'object') {
                    if (this.primaryKey && option[this.primaryKey]) {

                        return option[this.primaryKey]
                    } else {
                        return option[this.selectedProp];
                    }
                } else {
                    try {
                        return JSON.stringify(option)
                    } catch (e) {
                        return console.warn(
                            `[vue-select warn]: Could not stringify option ` +
                            `to generate unique key. Please provide 'getOptionKey' prop ` +
                            `to return a unique key for each option.\n` +
                            'https://vue-select.org/api/props.html#getoptionkey'
                        )
                        return null
                    }
                }
            }
        }
    }
</script>

<style scoped>
    .loader {
        text-align: center;
        color: #bbbbbb;
    }
</style>