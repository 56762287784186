<template>
    <b-row>

        <b-col
                cols="12"
                md="12"
                class="mb-1 table-container"
        >

            <div class="pb-2 mt-1 table-top-toolbar">

                <b-row>

                    <b-col
                            cols="12"
                            md="2"
                            style="min-width: 150px"
                            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                    >

                        <v-select

                                v-model="tableData.perPage"
                                :searchable="false"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="tableData.perPageOptions"
                                :clearable="false"
                                class="per-page-selector d-inline-block  mr-1"
                        >
                            <template v-slot:no-options="option">
                                {{$t('label_no_items')}}
                            </template>
                        </v-select>
                        <column-chooser v-model="selectedColumns" :not-touch="['id']"
                                        :columns="columnsDefs"></column-chooser>

                    </b-col>
                    <b-col
                            cols="12"
                            md="2"
                            class=" mb-1 date-range-col"
                    >
                        <label>{{ $t('label_priority') }}</label>
                        <!--:default-selection="defaultCreator"-->
                        <infinity-scroll
                                v-model="filterData.priority_id"
                                selected-prop="id"
                                url="/select_options"
                                :query-params="{options:['task_priorities'], infinity: 1}"
                                :multiple="false"
                                :placeholder="$t('label_select')"

                        >
                            <template #label="{item}">{{$t(item.translation_index)}}</template>

                        </infinity-scroll>

                    </b-col>
                    <b-col
                            cols="12"
                            md="2"
                            class=" mb-1 date-range-col"
                    >
                        <label>{{ $t('label_task_customer') }}</label>
                        <!--:default-selection="defaultCreator"-->
                        <infinity-scroll
                                v-model="filterData.task_creator_id"
                                selected-prop="id"
                                url="/user"
                                :multiple="false"
                                :placeholder="$t('label_select')"

                        >
                            <template #label="{item}">{{item.name}}</template>

                        </infinity-scroll>

                    </b-col>
                    <b-col
                            cols="12"
                            md="2"
                            class=" mb-1 date-range-col"
                    >
                        <label>{{ $t('label_task_executor') }}</label>

                        <infinity-scroll
                                v-model="filterData.task_executor_id"
                                selected-prop="id"
                                url="/user"
                                :multiple="false"
                                :placeholder="$t('label_select')"
                                :default-selection="defaultExecutor"
                        >
                            <template #label="{item}">{{item.name}}</template>

                        </infinity-scroll>

                    </b-col>
                    <b-col v-if="showAll && $can('edit','agreement.tab_tasks') || $can('edit','agreement.deal_tasks')"
                           cols="12"
                           md="2"
                           style="min-width: 160px"
                           class="d-flex ml-auto align-items-center"
                    >

                        <b-button  class="ml-auto" variant="primary" @click="$emit('add-item',{});$bvModal.show(PREFIX + '-modal')">
                            {{$t('label_add_task')}}
                        </b-button>

                    </b-col>

                </b-row>

                <b-row class="mt-1">
                    <b-col cols="12">
                        <b-form-radio v-for="item in taskTypes"
                                      class="mr-1 mb-1"
                                      v-model="filterData.task_type"
                                      button
                                      size="sm"
                                      button-variant="outline-primary"
                                      :value="item.alias"
                        >
                            <span style="white-space: nowrap;">{{$t(item.label)}}</span>
                        </b-form-radio>
                    </b-col>
                </b-row>

            </div>

            <div class="table-container-wrap">
                <b-table
                        :ref="PREFIX+'_TABLE'"
                        class="position-relative transited-table"
                        :items="tableItems"
                        responsive
                        no-border-collapse
                        :busy="isBusy"
                        :fields="selectedColumns"
                        primary-key="id"
                        :sort-by="tableData.sortBy"
                        no-local-sorting
                        :sort-desc.sync="tableData.sortDesc"
                        show-empty
                        :tbody-transition-props="{ name: 'flip-list'}"
                        :empty-text="$t('label_no_table_data')"
                        :tbody-tr-class="resolveRowClassVariant"
                >

                    <template #head()="data">
                        <span class="text-secondary">{{ $t(data.label).ucFirst() }}</span>
                    </template>
                    <template #head(checkboxes)="data">
                        <b-dropdown v-if="checkedIds.length > 0"
                                    class="selected-items-dropdown"
                                    variant="link"
                                    no-caret
                                    :right="$store.state.appConfig.isRTL"
                        >
                            <template #button-content>
                                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
                            </template>

                            <b-dropdown-item
                                    @click="deleteSelectedItems()">
                                <feather-icon icon="TrashIcon"/>
                                <span class="align-middle ml-50">{{ $t('label_delete_selected') }}</span>
                            </b-dropdown-item>

                        </b-dropdown>

                        <span v-else class="table-rows-actions">{{ $t(data.label).toUpperCase() }}</span>

                    </template>
                    <template #cell(checkboxes)="data">

                        <b-form-checkbox
                                :value="data.item.id"
                                v-model="checkedIds"

                        >

                        </b-form-checkbox>

                    </template>

                    <template #cell(task)="data">

                        <div style="min-width: 350px">
                            <partial-text class="cursor-pointer"
                                          @click="$router.push({name: MODULE_PREFIX+'_'+PREFIX+'_details', params: { id: data.item.id }})"
                                          :key="data.item.id" :is-html="true" :text="data.item.task"></partial-text>
                        </div>

                    </template>

                    <template #cell(deadline_at)="data">

                       {{formatDate(data.item.deadline_at, 'HH:mm DD.MM.YYYY', 'unix')}}
                       <template v-if="data.item.is_cyclic == 1">
                           <br>
                           <span class="badge badge-light-secondary"><feather-icon icon="ClockIcon"/>
                                {{$t(resolveDeadlineText(data.item.name_alias))}}</span>
                       </template>

                    </template>

                    <template #cell(executors)="data">
                                <span v-for="user,ind in data.item.executors"><span class="text-primary">{{user.name}}</span> </span>
                    </template>

                    <template #cell(creator)="data">
                                <span v-if="data.item.user"><span class="text-primary">{{data.item.user.name}}</span> </span>
                    </template>

                    <template #cell(task_type_name)="data">

                        <template v-if="data.item.task_type">
                            <b-avatar :id="'popover-task-type-'+data.item.id"
                                      rounded
                                      color="red"
                                      size="30"
                                      variant="light-primary"
                            >
                                <feather-icon
                                        size="18"
                                        :icon="data.item.task_type.icon?data.item.task_type.icon : 'MinusIcon'"
                                />
                            </b-avatar>

                            <b-popover
                                    boundary="window"
                                    class="bg-info"
                                    :target="'popover-task-type-'+data.item.id"
                                    triggers="hover"
                                    placement="top"
                                    variant="secondary"
                            >

                                <div class="tooltip-content">
                                    <span class="badge badge-light-primary">{{data.item.task_type.translation_index? $t(data.item.task_type.translation_index) : data.item.task_type.name}}</span>
                                </div>
                            </b-popover>
                        </template>

                    </template>
                    <template #cell(agreement_deal_number)="data">
                      <div style="min-width: 80px">
                          <template v-if="data.item.agreement">
                              <router-link v-if="data.item.agreement.is_deal && data.item.agreement.deal && $can('view', 'deal.tab_details')"
                                           :to="{name: 'deal-details', params:{id:data.item.agreement.deal.id}}">
                                  {{data.item.agreement.deal_number}}
                              </router-link>
                              <router-link v-else-if="data.item.agreement &&  $can('view', 'agreement.tab_details')"
                                           :to="{name: 'agreement-details', params:{id:data.item.agreement.id}}">
                                  {{data.item.agreement.deal_number}}
                              </router-link>
                              <template v-else>
                                  {{data.item.agreement.deal_number}}
                              </template>
                          </template>
                          <span v-else>--</span>
                      </div>
                    </template>

                    <template #cell(finishing)="data">


                        {{data.item.finished_at? formatDate(data.item.finished_at, 'HH:mm DD.MM.YYYY', 'unix') : '--'}}


                    </template>


                    <template #cell(actions)="data">

                        <b-dropdown
                                dropleft
                                boundary="window"
                                variant="link"
                                no-caret
                                @show="$event.vueTarget.$refs['toggle'].closest('tr').classList.add('row-selected')"
                                @hide="$event.vueTarget.$refs['toggle'].closest('tr').classList.remove('row-selected')"
                                :right="$store.state.appConfig.isRTL"
                        >
                            <template #button-content>
                                <feather-icon
                                        icon="MoreVerticalIcon"
                                        size="16"
                                        class="align-middle text-body"
                                />
                            </template>

                            <template v-if="$can('edit', MODULE_PREFIX+'.tab_'+PREFIX+'s')">
                                <b-dropdown-item disabled>
                                    <span class="badge badge-light-primary d-block">{{ $t('label_change_status') }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="finishAgrmDealTask(data.item.id)">
                                    <span class="align-middle ml-50">{{ $t('label_done') }}</span>
                                </b-dropdown-item>
                            </template>

                            <b-dropdown-item disabled>
                                <div class="divider">
                                    <div class="divider-text">{{ $t('label_action') }}</div>
                                </div>
                            </b-dropdown-item>


                            <template v-if="$can('view', MODULE_PREFIX+'.tab_'+PREFIX+'s')">
                                <b-dropdown-item
                                        :to="{name: MODULE_PREFIX+'_'+PREFIX+'_details', params: { id: data.item.id }}">
                                    <feather-icon icon="EyeIcon"/>
                                    <span class="align-middle ml-50">{{ $t('label_view') }}</span>
                                </b-dropdown-item>
                            </template>

                            <template v-if="$can('edit', MODULE_PREFIX+'.tab_'+PREFIX+'s')">

                                <b-dropdown-item v-if="data.item.finished_at" @click="restartTask(data.item.id)">
                                    <feather-icon icon="RefreshCwIcon"/>
                                    <span class="align-middle ml-50">{{ $t('label_repeat_task') }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item v-else
                                                 @click="$emit('edit-item', data.item); $bvModal.show(PREFIX+'-modal')">
                                    <feather-icon icon="EditIcon"/>
                                    <span class="align-middle ml-50">{{ $t('label_edit') }}</span>
                                </b-dropdown-item>

                                <template v-if="data.item.is_trackable == 1 && !data.item.finished_at">
                                    <b-dropdown-item
                                            v-if="!data.item.workingtime"
                                            @click="startTrackerIfIsNotBusy(data.item)">
                                        <feather-icon icon="ClockIcon"/>
                                        <span class="align-middle ml-50">{{ $t('label_start_task') }}</span>
                                    </b-dropdown-item>

                                    <template v-else="data.item.workingtime">

                                        <b-dropdown-item @click="finishTracker(data.item.id)">
                                            <feather-icon icon="InboxIcon"/>
                                            <span class="align-middle ml-50">{{ $t('label_save') }}</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="finishTracker(data.item.id, true)">
                                            <feather-icon icon="SaveIcon"/>
                                            <span class="align-middle ml-50">{{ $t('label_save_and_finish') }}</span>
                                        </b-dropdown-item>

                                    </template>
                                </template>

                                <b-dropdown-item
                                        @click="$emit('delete-item', data.item.id);deleteItem(data.item.id)">
                                    <feather-icon icon="TrashIcon"/>
                                    <span class="align-middle ml-50">{{ $t('label_delete') }}</span>
                                </b-dropdown-item>
                            </template>
                        </b-dropdown>
                    </template>

                </b-table>
            </div>

            <!--pagination-->
            <div class="mx-2 mb-2">
                <b-row>

                    <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted">{{ $t('label_showed') + ' '+ $t('label_from').toLowerCase() + ' ' + dataMeta.from + ' ' + $t('label_to_date') + ' ' + dataMeta.to + ' ' + $t('label_of') + ' ' + dataMeta.of }} </span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >

                        <b-pagination
                                v-model="tableData.currentPage"
                                :total-rows="tableTotal"
                                :per-page="tableData.perPage"
                                first-number
                                last-number
                                class="mb-0 mt-1 mt-sm-0"
                                prev-class="prev-item"
                                next-class="next-item"
                        >
                            <template #prev-text>
                                <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                />
                            </template>
                        </b-pagination>

                    </b-col>

                </b-row>
            </div>
        </b-col>

    </b-row>
</template>

<script>

    import {
        BTable, BDropdownItem, BDropdown, BBadge, BPagination, BFormCheckbox, BButton, BAvatar, BTooltip, BFormRadio, BPopover
    } from 'bootstrap-vue'

    import partialText from '@/views/components/partialText'
    import columnChooser from '@/views/components/columnChooser'
    import  infinityScroll from '@/views/components/infinityScroll'

    import vSelect from 'vue-select'

    import {
        // AGREEMENT_DYNAMIC_STORE_NAME,
        AGREEMENT_PREFIX,
        DEAL_PREFIX,
        TASK_PREFIX as PREFIX,
        resolveDeadlineText,
        resolveUsersText,
        taskTypes,

    } from './../moduleHelper'

    export default {
        components: {
            BTable, BDropdownItem, BDropdown, BBadge, BPagination, BButton, BAvatar, BTooltip,
            vSelect, BFormCheckbox, partialText, columnChooser, BFormRadio, BPopover, infinityScroll
        },
        props: ['moduleItem', 'isDeal', 'showAll', 'extraFilterData'],
        data() {
            return {
                // AGREEMENT_DYNAMIC_STORE_NAME,
                AGREEMENT_PREFIX,
                DEAL_PREFIX,
                MODULE_PREFIX: '',
                PREFIX,
                selectedColumns: [],
                columnsDefs: [

                    // {label: 'label_#', key: 'checkboxes', sortable: false, thClass: 'table-action-wrap'},
                    {label: 'label_deal_number', key: 'agreement_deal_number', sortable: false},
                    {label: 'label_task_type', key: 'task_type_name', sortable: false},
                    {label: 'label_task_content', key: 'task', sortable: false},
                    {label: 'label_deadline', key: 'deadline_at', sortable: true},
                    {label: 'label_finishing', key: 'finishing', sortable: false},
                    {label: 'label_task_for', key: 'executors', sortable: false},
                    {label: 'label_task_from', key: 'creator', sortable: false},
                    {label: 'label_action', key: 'actions'}

                ],
                isBusy: false,
                tableTotal: 0,
                tableItems: [],

                tableData: {
                    currentPage: 1,
                    perPage: 100,
                    searchQuery: '',
                    sortBy: 'id',
                    sortDesc: true,
                    perPageOptions: [10, 25, 50, 100],
                },
                checkedIds: [],

                taskTypes,

                filterData: {
                    task_type: 'actual_tasks',
                    task_creator_id: null,
                    task_executor_id: null,
                    priority_id: null
                },
                resolveDeadlineText,
                resolveUsersText,
                workTimer: {},
                // defaultCreator:null,
                defaultExecutor:null,
            }
        },
        watch: {
            tableData: {
                handler: function (newVal, old) {
                    let self = this;
                    self.refreshTableId = setTimeout(function(){
                        clearInterval(self.refreshTableId);
                        self.refreshDataTable();
                    },100);
                },
                deep: true
            },
            filterData: {
                handler: function (newVal, old) {
                    this.refreshDataTable();
                },
                deep: true
            },
            extraFilterData: {
                handler: function (newVal, old) {
                    this.refreshDataTable();
                },
                deep: true
            },
            moduleItem: function(newVal, old){

                if (newVal) {
                    this.refreshDataTable();
                }
            }
        },
        computed: {
            dataMeta: (context) => {
                return {
                    from: context.tableData.perPage * (context.tableData.currentPage - 1) + (context.tableItems.length ? 1 : 0),
                    to: context.tableData.perPage * (context.tableData.currentPage - 1) + context.tableItems.length,
                    of: context.tableTotal,
                }
            },
        },
        methods: {
            refreshDataTable: function () {
                this.isBusy = true;
                let url = '/agreement_' + this.PREFIX + 's' ;

                let params = {

                    length: this.tableData.perPage,
                    start: (this.tableData.currentPage - 1) * this.tableData.perPage,
                    search: this.tableData.searchQuery,
                    sort_by: this.tableData.sortBy,
                    sort_desc: this.tableData.sortDesc? true : null,

                    extra_search: this.filterData.task_type,
                    task_creator_id: this.filterData.task_creator_id,
                    task_executor_id: this.filterData.task_executor_id,
                    priority_id: this.filterData.priority_id
                };

                if (!this.showAll) {

                    params['agreement_id'] = this.isDeal? this.moduleItem.agreement_id : this.moduleItem.id;
                } else {

                    params.task_type_id = this.extraFilterData.task_type_id;
                    params.deadline_at_from = this.extraFilterData.deadline_at_from;
                    params.deadline_at_to = this.extraFilterData.deadline_at_to;

                    if(this.extraFilterData.task_source) {
                        params[this.extraFilterData.task_source] = 1;
                    }

                }

                this.async('get', url, {
                    params: params
                }, function (resp) {

                    this.tableItems = resp.data.items;
                    this.tableTotal = resp.data.total;
                    this.isBusy = false;
                });
            },
            deleteItem(id) {
                let deleteAll = false;
                let allowDeleting = false;
                this.$swal({
                    title: this.$t('label_deleting'),
                    text: this.$t('label_no_restore') + '!',
                    input: 'checkbox',
                    icon: 'warning',
                    inputValue: 0,
                    inputPlaceholder: this.$t('label_delete_all_documents'),
                    showClass: {
                        popup: 'animate__animated animate__flipInX',
                    },
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1',
                    },
                    buttonsStyling: false,
                    showCancelButton: true,
                    confirmButtonText: this.$t('label_submit'),
                    cancelButtonText: this.$t('label_cancel'),
                    // cancelButtonText: this.$t('label_close'),
                    showLoaderOnConfirm: true,
                    preConfirm(checkboxVal) {
                        deleteAll = checkboxVal;
                        allowDeleting = true;
                    },
                }).then(result => {
                    if(allowDeleting) {
                        this.async('delete', '/'+this.AGREEMENT_PREFIX+'_' + this.PREFIX + 's/' + id, {remove_all_docs: deleteAll}, function (resp) {
                            this.checkedIds = this.checkedIds.filter((it)=>it != id);
                            this.refreshDataTable();
                        });
                    }
                });
            },
            deleteSelectedItems() {
                let deleteAll = false;
                let allowDeleting = false;
                this.$swal({
                    title: this.$t('label_deleting'),
                    text: this.$t('label_no_restore') + '!',
                    input: 'checkbox',
                    icon: 'warning',
                    inputValue: 0,
                    inputPlaceholder: this.$t('label_delete_all_documents'),
                    showClass: {
                        popup: 'animate__animated animate__flipInX',
                    },
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1',
                    },
                    buttonsStyling: false,
                    showCancelButton: true,
                    confirmButtonText: this.$t('label_submit'),
                    cancelButtonText: this.$t('label_cancel'),
                    // cancelButtonText: this.$t('label_close'),
                    showLoaderOnConfirm: true,
                    preConfirm(checkboxVal) {
                        deleteAll = checkboxVal;
                        allowDeleting = true;
                    },
                }).then(result => {
                    if(allowDeleting) {
                        this.async('post', '/' + this.AGREEMENT_PREFIX + '_' + this.PREFIX + 's/remove_selected_tasks', {
                            remove_all_docs: deleteAll,
                            tasks_ids: this.checkedIds
                        }, function (resp) {
                            this.checkedIds = [];
                            this.refreshDataTable();
                        });
                    }
                })

            },

            resolveRowClassVariant(item, type) {
                if (!item || type !== 'row') return
                if (this.checkedIds.includes(item.id)) return 'row-success';
                if (item.finished_at) return 'row-success';
            },
            finishAgrmDealTask(id) {
                this.async('put', '/'+this.AGREEMENT_PREFIX+'_' + this.PREFIX + 's/finish/' + id, {}, function (resp) {
                    this.checkedIds = [];
                    this.refreshDataTable();
                });
            },
            restartTask(id) {
                this.async('put', '/'+this.AGREEMENT_PREFIX+'_' + this.PREFIX + 's/restart/' + id, {}, function (resp) {
                    this.checkedIds = [];
                    this.refreshDataTable();
                });

            },
            startTrackerIfIsNotBusy(task) {
                if (!this.workTimer.isTimerReset()) {
                    this.showToast('warning', this.$t('label_complete_the_current_task'));
                } else {
                    let data = {task_id:task.id, deal_id: task.agreement.id, name_activity: task.task_type_name, declarative_time:2, status:2, type: 'agreement_task', ['timer-action']: 'adding_new'};
                    // this.$store.state.app.editedWorkingTime = {relatedModuleItem: task.deal};
                    this.async('post', '/workingtime/api_process_working_time_report', data, function(resp){
                            this.workTimer.displayTimer();
                    });
                }
            },
            finishTracker(task_id, moveToNotCalculated){
                this.workTimer.stopTimer(true, true, moveToNotCalculated);
                this.finishAgrmDealTask(task_id);
            }
        },
        created() {
            let self = this;
            this.$root.$refs[this.PREFIX + '_CONTENT_TAB'] = this;
            this.MODULE_PREFIX = this.isDeal ? this.DEAL_PREFIX : this.AGREEMENT_PREFIX;

            this.columnsDefs = this.columnsDefs.filter(function (obj) {
                if (obj.key == 'checkboxes' && !self.$can('edit', 'agreement.tab_' + self.PREFIX + 's')) {
                    return false;
                } else if (obj.key == 'actions' && !self.$can('edit', 'agreement.tab_' + self.PREFIX + 's')) {
                    return false;
                } else if (obj.key == 'agreement_deal_number' && !self.showAll) {
                    return false;
                }
                return true;

            });

            let authUser = this.authUser();
            this.filterData.task_executor_id  = authUser.id;

            this.defaultExecutor = {id:authUser.id, name: authUser.name};

            if(this.showAll || this.moduleItem){
                this.refreshDataTable();
            }

            this.workTimer = this.$root.$refs.worktimer;

        },

        beforeDestroy(){
           delete this.$root.$refs[this.PREFIX + '_CONTENT_TAB'];
        }
    }
</script>