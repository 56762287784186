<template>
    <!--static-->
    <b-modal
            :id="PREFIX + '-modal'"
            hide-footer
            @hide="resetForm()"
            @shown="initData()"
    >
        <template #modal-title>
            {{action == 'editing'?$t('label_add_a_new_clause'):$t('label_add_a_new_clause')}}
        </template>
        <template #default="{ hide }">


            <validation-observer
                    tag="form"
                    class="p-1"
                    #default="{handleSubmit, reset, invalid}"
                    :ref="PREFIX + '_FORM'"

            >

                <validation-provider
                        #default="validationProps"
                        :name="$t('label_name')"
                        rules="required"
                        slim
                        mode="aggressive"
                >
                    <b-form-group
                            :label="$t('label_name')"
                    >
                        <b-form-input
                                v-model="itemData.name"
                                :state="getValidationState(validationProps)"
                                :placeholder="$t('label_name')"
                        />

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>


                <validation-provider

                        #default="validationProps"
                        :name="$t('label_content')"
                        rules="required"
                        slim
                >
                    <b-form-group
                            :label="$t('label_content')"
                    >
                        <b-form-textarea
                                :state="getValidationState(validationProps)"
                                v-model="itemData.content"
                                :placeholder="$t('label_content')"
                                rows="5"
                        ></b-form-textarea>

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <!--<span class="bage bage-light">{{$t('label_effective_date')}}</span>-->
                <b-row>
                    <b-col cols="12" md="6">
                        <validation-provider

                                #default="validationProps"
                                :name="$t('label_effective_date') + ' ' + $t('label_from').toLowerCase()"
                                rules="required"
                                class="mr-1 flex-grow-1"
                                slim
                        >
                            <b-form-group :label="$t('label_effective_date') + ' ' + $t('label_from').toLowerCase()">

                                <custom-date-picker
                                        :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                                        :locale="$store.state.appConfig.locale"
                                        :placeholder="$t('label_DD-MM-YYYY')"
                                        :value="itemData.effective_from_at"

                                        @input="itemData.effective_from_at = $event"
                                >
                                    <template #clear-btn="{ vm }">
                                        <feather-icon icon="XIcon" size="14"/>
                                    </template>
                                    <div slot="label">
                                        <feather-icon title="Clear" data-toggle icon="CalendarIcon" size="18"/>
                                    </div>
                                </custom-date-picker>


                                <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                    {{ validationProps.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col cols="12" md="6">
                        <validation-provider
                                v-if="itemData.is_termless != 1"
                                #default="validationProps"
                                :name="$t('label_until_is_it_in_force')"
                                rules="required"
                                class="mr-1 flex-grow-1"
                                slim
                        >
                            <b-form-group
                                    :label="$t('label_until_is_it_in_force')"

                            >

                                <custom-date-picker
                                        :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                                        :locale="$store.state.appConfig.locale"
                                        :placeholder="$t('label_DD-MM-YYYY')"
                                        :value="itemData.effective_to_at"

                                        @input="itemData.effective_to_at = $event"
                                >
                                    <template #clear-btn="{ vm }">
                                        <feather-icon icon="XIcon" size="14"/>
                                    </template>
                                    <div slot="label">
                                        <feather-icon title="Clear" data-toggle icon="CalendarIcon" size="18"/>
                                    </div>
                                </custom-date-picker>

                                <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                    {{ validationProps.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                </b-row>

                <validation-provider
                        class="mt-2"
                        #default="validationProps"
                        :name="$t('label_termless')"
                        slim
                >
                    <b-form-group label="">
                        <!--:checked="itemData.google_sync"-->
                        <b-form-checkbox
                                id="rodo-termless"
                                value="1"
                                unchecked-value="0"
                                v-model="itemData.is_termless"
                                @change="updateEffectiveToDate($event)">
                            <label for="rodo-termless">{{ $t('label_termless') }}</label>
                        </b-form-checkbox>
                    </b-form-group>
                </validation-provider>

                <div class="d-flex justify-content-end mt-2">
                    <button @click.prevent="$bvModal.hide(PREFIX + '-modal')" type="reset" class=" btn btn-default">
                        {{$t('label_cancel')}}
                    </button>

                    <button @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)" type="submit"
                            class="btn btn-primary waves-effect waves-light">{{(action ==
                        'editing')?$t('label_save'):$t('label_add')}}
                    </button>
                </div>

            </validation-observer>

        </template>

    </b-modal>
</template>


<script>
    import {ValidationProvider, ValidationObserver} from 'vee-validate'

    import {
        BFormTextarea,
        BFormGroup, BFormInput, BFormInvalidFeedback, BInputGroupAppend, BInputGroup, BFormCheckbox, BInputGroupPrepend,
    } from 'bootstrap-vue'

    import {AGREEMENT_PREFIX, DEAL_PREFIX, RODO_PREFIX as PREFIX} from './../moduleHelper'

    export default {
        components: {

            BFormGroup,
            BFormInput,
            BFormInvalidFeedback,
            BFormTextarea,
            BInputGroup,
            BInputGroupAppend,
            BFormCheckbox,
            BInputGroupPrepend,

            ValidationProvider,
            ValidationObserver,
            // vSelect
        },
        props: ['moduleItem', 'editedItem', 'isDeal'],
        data() {
            return {
                AGREEMENT_PREFIX,
                DEAL_PREFIX,
                MODULE_PREFIX: '',
                PREFIX,

                action: 'adding',

                blankItemData: {
                    name: '',
                    content: '',
                    effective_from_at: null,
                    effective_to_at: null,
                    is_termless: 0
                },

                itemData: false

            }
        },
        methods: {
            updateEffectiveToDate() {
                if (this.itemData.is_termless == 1) {
                    this.itemData.effective_to_at = null;
                }
            },
            initData() {

                if (this.editedItem) {
                    this.action = 'editing';

                    let item = Object.assign({}, this.blankItemData);
                    item.id = this.editedItem.id;
                    for (let prop in item) {
                        if (this.editedItem.hasOwnProperty(prop)) {
                            item[prop] = this.editedItem[prop];
                        }
                    }
                    item.effective_to_at = formatDate(item.effective_to_at, 'DD/MM/YYYY', 'unix');
                    item.effective_from_at = formatDate(item.effective_from_at, 'DD/MM/YYYY', 'unix');
                    this.itemData = item;
                } else {
                    this.action = 'adding';
                    this.itemData = Object.assign({}, this.blankItemData)
                }
            },
            resetForm() {
                this.itemData = Object.assign({}, this.blankItemData);
                this.$refs[this.PREFIX + '_FORM'].reset();
            },
            onSubmit() {

                this.itemData[this.AGREEMENT_PREFIX + '_id'] = this.isDeal? this.moduleItem.agreement_id  : this.moduleItem.id;
                if (this.action == 'adding') {

                    this.async('post', '/' + this.AGREEMENT_PREFIX + '_' + this.PREFIX, this.itemData, function (resp) {
                        this.$emit('item-added', {});
                        this.$bvModal.hide(this.PREFIX + '-modal');
                    });

                } else {
                    this.async('put', '/' + this.AGREEMENT_PREFIX + '_' + this.PREFIX + '/' + this.itemData.id, this.itemData, function (resp) {
                        this.$emit('item-edited', {});
                        this.$bvModal.hide(this.PREFIX + '-modal');
                    });

                }

            }
        },
        created() {
            this.MODULE_PREFIX = this.isDeal ? this.DEAL_PREFIX : AGREEMENT_PREFIX;
        }

    }
</script>