<template>
    <div v-if="agreementType">
        <br>
        <label>{{$t('label_rewards_and_payments')}}</label>
        <hr style="margin-top:4px">
        <div id="rewards-block">

            <!--payment section 1-->

            <div v-if="agreementType == 'provision_legal_services'" id="provision_legal_services">

                <div class="provision_legal_services reward-section">
                    <div class="row payment-type">
                        <div class="col-md-4">


                            <validation-provider
                                    #default="validationProps"
                                    :name="$t('label_preparatory_activities')"
                                    slim

                            >
                                <b-form-group
                                        label=""
                                        label-for="meeting_type_id"
                                >

                                    <b-form-checkbox
                                            id="pl-preparatory-activities"
                                            value="preparatory_activities"
                                            @change="payment.types.preparatory_activities = (provision_legal_services.includes('preparatory_activities')? 1 : 0);"
                                            v-model="provision_legal_services"
                                    >
                                        <label for="pl-preparatory-activities">{{ $t('label_preparatory_activities') }}</label>
                                    </b-form-checkbox>
                                </b-form-group>
                            </validation-provider>
                        </div>

                        <div v-if="payment.types.preparatory_activities" class="col-md-4 pay-depends">

                            <validation-provider
                                    #default="validationProps"
                                    :name="$t('label_payment_amount_netto')"
                                    rules="required|price"
                                    slim
                                    vid="amount_netto_1"
                            >
                                <b-form-group

                                        :label="$t('label_payment_amount_netto')"
                                        label-for="payment_amount_netto"
                                >
                                    <b-form-input
                                            class="price-input"
                                            id="payment_amount_netto"
                                            v-model="payment.preparatory_activities.net_payment"
                                            :state="getValidationState(validationProps)"
                                            :placeholder="$t('label_payment_amount_netto')"
                                    />

                                    <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                        {{ validationProps.errors[0] }}
                                    </b-form-invalid-feedback>

                                </b-form-group>
                            </validation-provider>
                        </div>

                        <div v-if="payment.types.preparatory_activities" class="col-md-4 pay-depends" style="min-width: 200px;">

                            <validation-provider
                                    #default="validationProps"
                                    :name="$t('label_term_of_payment')"
                                    rules="required"
                                    slim
                                    vid="term_of_payment_1"

                            >
                                <b-form-group
                                        :label="$t('label_term_of_payment')"
                                        label-for="limitation_date"
                                        :state="getValidationState(validationProps)"
                                >

                                    <custom-date-picker
                                            :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                                            :locale="$store.state.appConfig.locale"
                                            :placeholder="$t('label_DD-MM-YYYY')"
                                            :value="payment.preparatory_activities.deadline_at"

                                            @input="payment.preparatory_activities.deadline_at = $event"
                                    >
                                        <template #clear-btn="{ vm }"><feather-icon icon="XIcon" size="14"/></template>
                                        <div slot="label">
                                            <feather-icon title="Clear" data-toggle icon="CalendarIcon" size="18"/>
                                        </div>
                                    </custom-date-picker>

                                    <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                        {{ validationProps.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </div>

                    </div>
                    <div class="row payment-type">
                        <div class="col-md-4">

                            <validation-provider
                                    #default="validationProps"
                                    :name="$t('label_flat_rate_reward')"
                                    slim

                            >
                                <b-form-group
                                        label=""
                                        label-for="meeting_type_id"
                                >

                                    <b-form-checkbox
                                            id="pl-flat-rate-reward"
                                            value="flat_rate_reward"
                                            @input="payment.types.flat_rate_reward = (provision_legal_services.includes('flat_rate_reward')? 1 : 0)"

                                            v-model="provision_legal_services"
                                    >
                                        <label for="pl-flat-rate-reward">{{ $t('label_flat_rate_reward') }}</label>
                                    </b-form-checkbox>
                                </b-form-group>
                            </validation-provider>
                        </div>

                        <div v-if="payment.types.flat_rate_reward" class="col-md-4 pay-depends">

                            <validation-provider
                                    #default="validationProps"
                                    :name="$t('label_payment_amount_netto')"
                                    rules="required|price"
                                    slim
                                    vid="amount_netto_2"
                            >
                                <b-form-group
                                        :label="$t('label_payment_amount_netto')"
                                        label-for="payment_amount_netto_1"
                                >
                                    <b-form-input
                                            class="price-input"
                                            id="payment_amount_netto_1"
                                            v-model="payment.flat_rate_reward.net_payment"
                                            :state="getValidationState(validationProps)"
                                            :placeholder="$t('label_payment_amount_netto')"
                                    />

                                    <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                        {{ validationProps.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>

                        </div>

                        <div v-if="payment.types.flat_rate_reward" class="col-md-4 pay-depends" style="min-width: 200px;">

                            <validation-provider
                                    #default="validationProps"
                                    :name="$t('label_term_of_payment')"
                                    rules="required"
                                    vid="term_of_payment_2"
                            >
                                <b-form-group
                                        :label="$t('label_term_of_payment')"
                                        label-for="limitation_date"
                                        :state="getValidationState(validationProps)"
                                >

                                    <custom-date-picker
                                            :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                                            :locale="$store.state.appConfig.locale"
                                            :placeholder="$t('label_DD-MM-YYYY')"
                                            :value="payment.flat_rate_reward.deadline_at"

                                            @input="payment.flat_rate_reward.deadline_at = $event"
                                    >
                                        <template #clear-btn="{ vm }"><feather-icon icon="XIcon" size="14"/></template>
                                        <div slot="label">
                                            <feather-icon title="Clear" data-toggle icon="CalendarIcon" size="18"/>
                                        </div>
                                    </custom-date-picker>

                                    <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                        {{ validationProps.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </div>

                    </div>
                    <div class="row payment-type">
                        <div class="col-md-4">

                            <validation-provider
                                    #default="validationProps"
                                    :name="$t('label_success_fee')"
                                    slim
                                    :rules="isAnnex? '' :'required-at-least-one'"
                                    :skipIfEmpty="false"
                            >
                                <b-form-group
                                        label=""
                                        label-for="success_fee"

                                >
                                    <!--:checked="itemData.google_sync"-->
                                    <!---->
                                    <b-form-checkbox
                                            id="pl-success-fee"
                                            value="success_fee"
                                            @input="payment.types.success_fee = (provision_legal_services.includes('success_fee')? 1 : 0);"
                                            v-model="provision_legal_services"
                                    >
                                        <label for="pl-success-fee">{{ $t('label_success_fee') }}</label>
                                    </b-form-checkbox>
                                    <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                                        {{ validationProps.errors[0] }}
                                    </b-form-invalid-feedback>

                                </b-form-group>
                            </validation-provider>
                        </div>

                        <div v-if="payment.types.success_fee" class="col-md-4 pay-depends">

                            <validation-provider
                                    #default="validationProps"
                                    :name="$t('label_net_commission_level_')"
                                    rules="required|price"
                                    slim
                                    vid="amount_netto_3"
                            >
                                <b-form-group
                                        :label="$t('label_net_commission_level_')"
                                        label-for="payment_amount_netto_3"
                                >
                                    <b-form-input
                                            class="price-input"
                                            id="payment_amount_netto_3"
                                            v-model="payment.success_fee.net_payment"
                                            :state="getValidationState(validationProps)"
                                            :placeholder="$t('label_net_commission_level_')"
                                    />

                                    <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                        {{ validationProps.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </div>

                    </div>
                    <div id="pay-section-1"></div>
                </div>
                <hr class="pay-divider">
            </div>

            <!--payment 2 section **************************************2-->

            <div v-if="agreementType == 'provision_redress_services'" id="provision_redress_services">
                <div class="provision_redress_services  reward-section">
                    <div class="row payment-type">
                        <div class="col-md-4">

                            <validation-provider
                                    #default="validationProps"
                                    :name="$t('label_preparation_fee')"
                                    slim
                            >
                                <b-form-group
                                        label=""
                                        label-for="preparation_fee"
                                >
                                    <b-form-checkbox
                                            id="pr-preparation-fee"
                                            value="preparation_fee"
                                            @input="payment.types.preparation_fee = (provision_redress_services.includes('preparation_fee')? 1 : 0)"
                                            v-model="provision_redress_services"
                                    >
                                        <label for="pr-preparation-fee">{{ $t('label_preparation_fee') }}</label>
                                    </b-form-checkbox>
                                </b-form-group>
                            </validation-provider>
                        </div>

                        <div v-if="payment.types.preparation_fee" class="col-md-4 pay-depends">

                            <validation-provider
                                    #default="validationProps"
                                    :name="$t('label_payment_amount_netto')"
                                    rules="required|price"
                                    slim
                                    vid="amount_netto_4"
                            >
                                <b-form-group
                                        :label="$t('label_payment_amount_netto')"
                                        label-for="payment_amount_netto_4"
                                >
                                    <b-form-input
                                            class="price-input"
                                            id="payment_amount_netto_4"
                                            v-model="payment.preparation_fee.net_payment"
                                            :state="getValidationState(validationProps)"
                                            :placeholder="$t('label_payment_amount_netto')"
                                    />

                                    <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                        {{ validationProps.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </div>

                        <div v-if="payment.types.preparation_fee" class="col-md-4 pay-depends" style="min-width: 200px;">

                            <validation-provider
                                    #default="validationProps"
                                    :name="$t('label_term_of_payment')"
                                    rules="required"
                            >
                                <b-form-group
                                        :label="$t('label_term_of_payment')"
                                        label-for="limitation_date"
                                        :state="getValidationState(validationProps)"
                                >

                                    <custom-date-picker
                                            :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                                            :locale="$store.state.appConfig.locale"
                                            :placeholder="$t('label_DD-MM-YYYY')"
                                            :value="payment.preparation_fee.deadline_at"

                                            @input="payment.preparation_fee.deadline_at = $event"
                                    >
                                        <template #clear-btn="{ vm }"><feather-icon icon="XIcon" size="14"/></template>
                                        <div slot="label">
                                            <feather-icon title="Clear" data-toggle icon="CalendarIcon" size="18"/>
                                        </div>
                                    </custom-date-picker>

                                    <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                        {{ validationProps.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </div>

                    </div>
                    <div class="row payment-type">
                        <div class="col-md-4">

                            <validation-provider
                                    #default="validationProps"
                                    :name="$t('label_success_fee')"
                                    slim
                                    :rules="isAnnex? '' :'required-at-least-one'"
                                    :skipIfEmpty="false"

                            >
                                <b-form-group
                                        label=""
                                        label-for="success_fee"
                                >

                                    <b-form-checkbox
                                            id="pr-success-fee"
                                            value="success_fee"
                                            @input="payment.types.success_fee = (provision_redress_services.includes('success_fee')? 1 : 0)"
                                            v-model="provision_redress_services"

                                    >
                                        <label for="pr-success-fee">{{ $t('label_success_fee') }}</label>
                                    </b-form-checkbox>
                                    <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                                        {{ validationProps.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>

                            </validation-provider>

                        </div>


                        <div v-if="payment.types.success_fee" class="col-md-4 pay-depends">

                            <validation-provider
                                    #default="validationProps"
                                    :name="$t('label_net_commission_level_')"
                                    rules="required|price"
                                    slim
                                    vid="amount_netto_5"
                            >
                                <b-form-group
                                        :label="$t('label_net_commission_level_')"
                                        label-for="payment_amount_netto_5"
                                >
                                    <b-form-input
                                            class="price-input"
                                            id="payment_amount_netto_5"
                                            v-model="payment.success_fee.net_payment"
                                            :state="getValidationState(validationProps)"
                                            :placeholder="$t('label_net_commission_level_')"
                                    />

                                    <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                        {{ validationProps.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </div>

                    </div>

                </div>
                <hr class="pay-divider">
            </div>

            <!--payment section 3 **************************************-->

            <div v-if="agreementType == 'legal_service_order_card'" id="legal_service_order_card" >

                <div class="legal_service_order_card  reward-section">

                    <h5 class="mb-2">1. {{$t('label_reward')}}</h5>

                    <div class="payment-type">

                        <div class="row">
                            <div class="col-md-3">

                                <validation-provider
                                        #default="validationProps"
                                        :name="$t('label_once_for_meeting')"
                                        slim
                                >
                                    <b-form-group
                                            label=""
                                            label-for="single_for_meeting"
                                    >

                                        <b-form-checkbox
                                                id="ls-single-for-meeting"
                                                value="single_for_meeting"
                                                @input="payment.types.single_for_meeting = (legal_service_order_card.includes('single_for_meeting')? 1 : 0)"
                                                v-model="legal_service_order_card"
                                        >
                                            <label for="ls-single-for-meeting">{{'a) '+ $t('label_once_for_meeting') }}</label>
                                        </b-form-checkbox>
                                    </b-form-group>
                                </validation-provider>

                            </div>

                            <div v-if="payment.types.single_for_meeting" class="col-md-3 pay-depends">

                                <validation-provider #default="validationProps" :name="$t('label_pln_net')"
                                                     rules="required|price"
                                                     slim
                                                     vid="amount_netto_6"
                                >
                                    <b-form-group :label="$t('label_pln_net')"
                                                  label-for="payment_amount_netto_6"
                                    >
                                        <b-form-input :state="getValidationState(validationProps)"
                                                      id="payment_amount_netto_6"
                                                      class="price-input"
                                                      v-model="payment.single_for_meeting.net_payment"
                                                      @input="payment.single_for_meeting.gross_payment = ($event.formatPriceToNumber()*1.23).formatPrice()"
                                                      :placeholder="$t('label_pln_net')"
                                        />

                                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                            {{ validationProps.errors[0] }}
                                        </b-form-invalid-feedback>

                                    </b-form-group>
                                </validation-provider>
                            </div>
                            <div v-if="payment.types.single_for_meeting" class="col-md-3 pay-depends">
                                <div class="form-group ">
                                    <label>{{$t('label_pln_gross')}}</label>
                                    <input type="text"
                                           readonly
                                           v-model="payment.single_for_meeting.gross_payment"
                                           :placeholder="$t('label_pln_gross')"
                                           class="form-control" >
                                </div>

                            </div>

                        </div>
                    </div>
                    <div class="payment-type">
                        <div class="row">
                            <div class="col-md-3">

                                <validation-provider
                                        #default="validationProps"
                                        :name="$t('label_preparatory')"
                                        slim
                                >
                                    <b-form-group
                                            label=""
                                            label-for="preparatory_payment"
                                    >

                                        <b-form-checkbox
                                                id="ls-preparatory"
                                                value="preparatory_payment"
                                                @input="payment.types.preparatory_payment = (legal_service_order_card.includes('preparatory_payment')? 1 : 0)"
                                                v-model="legal_service_order_card"
                                        >
                                            <label for="ls-preparatory">{{'b) ' + $t('label_preparatory') }}</label>
                                        </b-form-checkbox>
                                    </b-form-group>
                                </validation-provider>

                            </div>

                            <div v-if="payment.types.preparatory_payment" class="col-md-3 pay-depends">

                                <validation-provider #default="validationProps" :name="$t('label_pln_net')"
                                                     rules="required|price"
                                                     slim
                                                     vid="amount_netto_7"
                                >
                                    <b-form-group :label="$t('label_pln_net')"
                                                  label-for="payment_amount_netto_7"
                                    >
                                        <b-form-input :state="getValidationState(validationProps)"
                                                      id="payment_amount_netto_7"
                                                      class="price-input"
                                                      v-model="payment.preparatory_payment.net_payment"
                                                      @input="payment.preparatory_payment.gross_payment = ($event.formatPriceToNumber()*1.23).formatPrice()"
                                                      :placeholder="$t('label_pln_net')"
                                        />
                                        <div style="white-space: nowrap;">
                                            <label>{{$t('label_payable_before_the_start_of_the_order').ucFirst()}}</label>
                                        </div>
                                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                            {{ validationProps.errors[0] }}
                                        </b-form-invalid-feedback>

                                    </b-form-group>
                                </validation-provider>

                            </div>
                            <div v-if="payment.types.preparatory_payment" class="col-md-3 pay-depends">
                                <div class="form-group ">
                                    <label>{{$t('label_pln_gross')}}</label>
                                    <input type="text"
                                           readonly
                                           v-model="payment.preparatory_payment.gross_payment"
                                           :placeholder="$t('label_pln_gross')"
                                           class="form-control" >
                                </div>

                            </div>
                            <!--<div v-if="payment.types.preparatory_payment" class="col-md-push-3 col-md-9 pay-depends" style="margin-bottom: 12px;margin-top: -9px;">-->
                                <!--<span>{{$t('label_payable_before_the_start_of_the_order').ucFirst()}}</span>-->
                            <!--</div>-->
                        </div>

                    </div>
                    <div class="payment-type">
                        <div class="row">
                            <div class="col-md-3">

                                <validation-provider
                                        #default="validationProps"
                                        :name="$t('label_hourly_billing')"
                                        slim
                                >
                                    <b-form-group
                                            label=""
                                            label-for="hourly_payment"
                                    >

                                        <b-form-checkbox
                                                id="ls-hourly-billing"
                                                value="hourly_payment"
                                                @input="payment.types.hourly_payment = (legal_service_order_card.includes('hourly_payment')? 1 : 0)"
                                                v-model="legal_service_order_card"
                                        >
                                            <label for="ls-hourly-billing">{{'c) ' +  $t('label_hourly_billing') }}</label>
                                        </b-form-checkbox>
                                    </b-form-group>
                                </validation-provider>

                            </div>

                            <div v-if="payment.types.hourly_payment" class="col-md-3 pay-depends">

                                <validation-provider #default="validationProps" :name="$t('label_pln_net')"
                                                     rules="required|price"
                                                     slim
                                                     vid="amount_netto_8"
                                >
                                    <b-form-group :label="$t('label_pln_net')"
                                                  label-for="payment_amount_netto_8"
                                    >
                                        <b-form-input :state="getValidationState(validationProps)"
                                                      id="payment_amount_netto_8"
                                                      class="price-input"
                                                      v-model="payment.hourly_payment.net_payment"
                                                      @input="payment.hourly_payment.gross_payment = ($event.formatPriceToNumber()*1.23).formatPrice()"
                                                      :placeholder="$t('label_pln_net')"
                                        />
                                        <div style="white-space: nowrap;">
                                            <label>{{ $t('label_for_every_hour_of_work').ucFirst()}}</label>
                                        </div>

                                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                            {{ validationProps.errors[0] }}
                                        </b-form-invalid-feedback>

                                    </b-form-group>
                                </validation-provider>
                            </div>
                            <div v-if="payment.types.hourly_payment" class="col-md-3 pay-depends">
                                <div class="form-group ">
                                    <label>{{$t('label_pln_gross')}}</label>
                                    <input type="text"
                                           readonly
                                           v-model="payment.hourly_payment.gross_payment"
                                           :placeholder="$t('label_pln_gross')"
                                           class="form-control" >
                                </div>

                            </div>
                            <!--<div v-if="payment.types.hourly_payment" class="col-md-push-3 col-md-9 pay-depends" style="margin-bottom: 12px;margin-top: -9px;">-->
                                <!--<span>{{ $t('label_for_every_hour_of_work').ucFirst()}}</span>-->
                            <!--</div>-->

                        </div>

                    </div>
                    <div class="payment-type">
                        <div class="row">
                            <div class="col-md-3">

                                <validation-provider
                                        #default="validationProps"
                                        :name="$t('label_monthly_lump_sum')"
                                        slim
                                        :rules="isAnnex? '' :'required-at-least-one'"
                                        :skipIfEmpty="false"
                                >
                                    <b-form-group
                                            label=""
                                    >

                                        <b-form-checkbox
                                                id="ls-monthly-lump-sum"
                                                value="monthly_lump_sum"
                                                @input="payment.types.monthly_lump_sum = (legal_service_order_card.includes('monthly_lump_sum')? 1 : 0)"
                                                v-model="legal_service_order_card"
                                        >
                                            <label for="ls-monthly-lump-sum">{{'d) ' +  $t('label_monthly_lump_sum') }}</label>
                                        </b-form-checkbox>
                                        <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                                            {{ validationProps.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>

                            </div>

                            <div v-if="payment.types.monthly_lump_sum" class="col-md-3 pay-depends">

                                <validation-provider #default="validationProps" :name="$t('label_pln_net')"
                                                     rules="required|price"
                                                     slim
                                                     vid="amount_netto_9"
                                >
                                    <b-form-group :label="$t('label_pln_net')"
                                                  label-for="payment_amount_netto_9"
                                    >
                                        <b-form-input :state="getValidationState(validationProps)"
                                                      id="payment_amount_netto_9"
                                                      class="price-input"
                                                      v-model="payment.monthly_lump_sum.net_payment"
                                                      @input="payment.monthly_lump_sum.gross_payment = ($event.formatPriceToNumber()*1.23).formatPrice()"
                                                      :placeholder="$t('label_pln_net')"
                                        />
                                        <div style="white-space: nowrap;">
                                            <label>{{ $t('label_for_period').ucFirst()}}</label>
                                        </div>
                                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                            {{ validationProps.errors[0] }}
                                        </b-form-invalid-feedback>

                                    </b-form-group>
                                </validation-provider>
                            </div>
                            <div v-if="payment.types.monthly_lump_sum" class="col-md-3 pay-depends">
                                <div class="form-group ">
                                    <label>{{$t('label_pln_gross')}}</label>
                                    <input type="text"
                                           readonly
                                           v-model="payment.monthly_lump_sum.gross_payment"
                                           :placeholder="$t('label_pln_gross')"
                                           class="form-control" >
                                </div>

                            </div>
                            <div v-if="payment.types.monthly_lump_sum" class="col-md-2 pay-depends">

                                <validation-provider #default="validationProps" :name="$t('label_month_-s')"
                                                     rules="required|integer"
                                                     slim
                                                     vid="lump_sum_months"
                                >
                                    <b-form-group :label="$t('label_month_-s')"

                                    >
                                        <b-form-input :state="getValidationState(validationProps)"

                                                      v-model="payment.monthly_lump_sum.lump_sum_months"

                                                      :placeholder="$t('label_month_-s')"
                                        />

                                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                            {{ validationProps.errors[0] }}
                                        </b-form-invalid-feedback>

                                    </b-form-group>
                                </validation-provider>
                            </div>

                        </div>
                        <div v-if="payment.types.monthly_lump_sum" class="col-md-12 pay-depends">
                            <label class="inline-input-label tiny-input"
                                   style="margin-top: 10px; ">{{ '*' + $t('label_the_monthly_lump_sum_includes_a_limit_in_quantity')}}

                                <validation-provider #default="validationProps" :name="$t('label_monthly_lump_sum')"
                                                     rules="integer"
                                                     slim
                                >
                                    <b-form-group
                                            label-for="lump_sum_hours"
                                    >
                                        <b-form-input :state="getValidationState(validationProps)"
                                                      id="lump_sum_hours"
                                                      v-model="payment.monthly_lump_sum.lump_sum_hours"

                                                      :placeholder="$t('label_hours')"
                                        />

                                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                            {{ validationProps.errors[0] }}
                                        </b-form-invalid-feedback>

                                    </b-form-group>
                                </validation-provider>
                                {{$t('label_monthly_hours') + ')'}}
                            </label>


                        </div>
                    </div>
                    <h5 class="mb-2">2. {{$t('label_additional_reward')}}</h5>
                    <div class="payment-type">
                        <div class="row">
                            <div class="col-md-3">

                                <validation-provider
                                        #default="validationProps"
                                        :name="$t('label_for_each_participation_in_the_activity')"
                                        slim
                                >
                                    <b-form-group
                                            label=""
                                            label-for="each_participation_activity"
                                    >

                                        <b-form-checkbox
                                                id="each-participation-activity"
                                                value="1"
                                                unchecked-value="0"
                                                v-model="payment.types.each_participation_activity"
                                        >
                                            <label for="each-participation-activity">{{'a) ' +  $t('label_for_each_participation_in_the_activity') }}</label>
                                        </b-form-checkbox>
                                    </b-form-group>
                                </validation-provider>

                            </div>

                            <div v-if="payment.types.each_participation_activity" class="col-md-3 pay-depends">

                                <validation-provider #default="validationProps" :name="$t('label_pln_net')"
                                                     rules="required|price"
                                                     slim
                                                     vid="amount_netto_10"
                                >
                                    <b-form-group :label="$t('label_pln_net')"
                                                  label-for="payment_amount_netto_10"
                                    >
                                        <b-form-input :state="getValidationState(validationProps)"
                                                      id="payment_amount_netto_10"
                                                      class="price-input"
                                                      v-model="payment.each_participation_activity.net_payment"
                                                      @input="payment.each_participation_activity.gross_payment = ($event.formatPriceToNumber()*1.23).formatPrice()"
                                                      :placeholder="$t('label_pln_net')"
                                        />

                                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                            {{ validationProps.errors[0] }}
                                        </b-form-invalid-feedback>

                                    </b-form-group>
                                </validation-provider>
                            </div>
                            <div v-if="payment.types.each_participation_activity" class="col-md-3 pay-depends">
                                <div class="form-group ">
                                    <label>{{$t('label_pln_gross')}}</label>
                                    <input type="text"
                                           readonly
                                           v-model="payment.each_participation_activity.gross_payment"
                                           :placeholder="$t('label_pln_gross')"
                                           class="form-control" >
                                </div>
                            </div>

                        </div>

                    </div>
                    <h5 class="mb-2">
                        3. {{$t('label_settlement_method') + ' / ' + $t('label_payment_terms')}}</h5>
                    <div>
                        <div class="row" id="legal-service-options">
                            <div class="col-md-12">

                                <validation-provider
                                        #default="validationProps"
                                        :name="$t('label_paid_in_cash')"
                                        slim
                                >
                                    <b-form-group
                                            label=""
                                            label-for="each_participation_activity"
                                    >

                                        <b-form-checkbox
                                                id="opt-pay-in-cash"
                                                v-model="legal_service_order_card_options"
                                                value="pay_in_cash"
                                                @input="payment.options.pay_in_cash = (legal_service_order_card_options.includes('pay_in_cash')? 1 : 0);"

                                        >
                                            <label for="opt-pay-in-cash">{{'a) ' +  $t('label_paid_in_cash') }}</label>
                                        </b-form-checkbox>
                                    </b-form-group>
                                </validation-provider>

                            </div>
                            <div class="col-md-12">

                                <div class="d-flex align-items-center ">
                                    <validation-provider
                                            #default="validationProps"
                                            :name="$t('label_before_each_implementation_on_time')"
                                            slim
                                    >
                                        <b-form-group
                                                style="margin-bottom: 0;"
                                                label=""
                                        >

                                            <b-form-checkbox
                                                    v-model="legal_service_order_card_options"
                                                    value="pay_for_each_participation"
                                                    @input="payment.options.pay_for_each_participation = (legal_service_order_card_options.includes('pay_for_each_participation')? 1 : 0);"
                                            >
                                                <!--<label for=""></label>-->

                                            </b-form-checkbox>
                                        </b-form-group>
                                    </validation-provider>
                                    <label class="inline-input-label tiny-input"
                                           style="margin-top: 10px; ">{{'b) ' +  $t('label_before_each_implementation_on_time') }}

                                        <validation-provider #default="validationProps" :name="$t('label_days')"
                                                             rules="integer"
                                                             slim
                                        >
                                            <b-form-group
                                                    label-for="each_participation_days"
                                            >
                                                <b-form-input :state="getValidationState(validationProps)"
                                                              id="each_participation_days"
                                                              v-model="payment.options.each_participation_days"

                                                              :placeholder="$t('label_days')"
                                                />
                                                <!--<input v-model="payment.options.each_participation_days" type="number" min="0"-->
                                                <!--class="form-control inline-input"> {{$t('label_days')}}-->
                                                <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                                    {{ validationProps.errors[0] }}
                                                </b-form-invalid-feedback>

                                            </b-form-group>
                                        </validation-provider>&nbsp;{{$t('label_days') }}
                                    </label>
                                </div>

                            </div>
                            <div class="col-md-12">

                                <div class="d-flex align-items-center">
                                    <validation-provider
                                            #default="validationProps"
                                            :name="$t('label_payment_after_the_end_of_the_month_on_time')"
                                            slim
                                    >
                                        <b-form-group
                                                label=""
                                                style="margin-bottom: 0;"
                                        >

                                            <b-form-checkbox
                                                    v-model="legal_service_order_card_options"
                                                    value="pay_after_month"
                                                    @input="payment.options.pay_after_month = (legal_service_order_card_options.includes('pay_after_month')? 1 : 0);"
                                            >
                                                <!--<label for=""></label>-->

                                            </b-form-checkbox>
                                        </b-form-group>
                                    </validation-provider>
                                    <label class="inline-input-label tiny-input"
                                           style="margin-top: 10px; ">{{'c) ' +  $t('label_payment_after_the_end_of_the_month_on_time') }}

                                        <validation-provider #default="validationProps" :name="$t('label_days')"
                                                             rules="integer"
                                                             slim
                                        >
                                            <b-form-group

                                                    label-for="pay_after_month_days"
                                            >

                                                <b-form-input :state="getValidationState(validationProps)"
                                                              id="pay_after_month_days"
                                                              v-model="payment.options.pay_after_month_days"

                                                              :placeholder="$t('label_days')"
                                                />

                                                <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                                    {{ validationProps.errors[0] }}
                                                </b-form-invalid-feedback>

                                            </b-form-group>
                                        </validation-provider>
                                        {{$t('label_days') }}
                                    </label>
                                </div>

                            </div>

                        </div>
                    </div>

                    <!--next-->
                    <!--<div id="pay-section-3"></div>-->
                </div>
                <hr class="pay-divider">
            </div>

            <!--END payment section 3-->

        </div>
    </div>
</template>
<script>
    import {
         BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BCard, BFormTextarea, BFormCheckbox
    } from 'bootstrap-vue'

    import { ValidationProvider, ValidationObserver} from 'vee-validate'

    import { requiredAtLeastOne } from '@validations'

    import vSelect from 'vue-select'

    import { AGREEMENT_PREFIX as PREFIX} from './../moduleHelper'

    export default {
        components: {

            BFormGroup,
            BFormInput,
            BFormInvalidFeedback,
            BButton, BCard,
            vSelect,

            BFormCheckbox,
            BFormTextarea,

            ValidationProvider,
            ValidationObserver,
            requiredAtLeastOne

        },

        props: ['agreementType', 'isAnnex' ,'itemPayment'],
        data() {
            return {
                PREFIX,

                provision_legal_services: [],
                provision_redress_services: [],
                legal_service_order_card: [],

                legal_service_order_card_options: [],
                availableOptions: ['pay_in_cash', 'pay_for_each_participation', 'pay_after_month'],

                payment: {

                    options: {
                        pay_in_cash: 0,
                        pay_for_each_participation: 0,
                        each_participation_days: '',
                        pay_after_month: 0,
                        pay_after_month_days: ''
                    },
                    types: {
                        preparatory_activities: 0,
                        flat_rate_reward: 0,
                        success_fee: 0,
                        preparation_fee: 0,
                        single_for_meeting: 0,
                        preparatory_payment: 0,
                        hourly_payment: 0,
                        monthly_lump_sum: 0,
                        each_participation_activity: 0,
                    },
                    preparatory_activities: {
                        net_payment: '',
                        deadline_at: ''
                    },
                    flat_rate_reward: {
                        net_payment: '',
                        deadline_at: ''
                    },
                    success_fee: {
                        net_payment: ''
                    },
                    preparation_fee: {
                        net_payment: '',
                        deadline_at: ''
                    },
                    single_for_meeting: {
                        net_payment: '',
                        gross_payment: ''
                    },
                    preparatory_payment: {
                        net_payment: '',
                        gross_payment: ''
                    },
                    hourly_payment: {
                        net_payment: '',
                        gross_payment: ''
                    },
                    monthly_lump_sum: {
                        net_payment: '',
                        gross_payment: '',
                        lump_sum_months: '',
                        lump_sum_hours: ''
                    },
                    each_participation_activity: {
                        net_payment: '',
                        gross_payment: '',
                    }
                }
            }
        },
        watch: {
            payment: {
                handler: function (newVal, old) {
                    this.$emit('paymentDataUpdated', newVal);
                },
                deep: true
            },
            itemPayment: function (newVal, old) {
                this.assignDefaultPaymentData();
            },
            agreementType: function (newVal, old) {
                this.assignDefaultPaymentData();
            },
                legal_service_order_card_options: function (newVal, old) {

                    if (newVal.length > 1) {

                        let selVal = newVal.slice(-1);
                        this.legal_service_order_card_options=selVal;
                    }
                },
            legal_service_order_card: function (newVal, old) {

                let selVal = newVal.slice(-1);

                    if (newVal.includes('monthly_lump_sum') &&  selVal == 'hourly_payment'){
                        this.legal_service_order_card = this.legal_service_order_card.deleteVal('monthly_lump_sum');

                    } else if(newVal.includes('hourly_payment') && selVal == 'monthly_lump_sum') {
                        this.legal_service_order_card = this.legal_service_order_card.deleteVal('hourly_payment');
                    }

                },
        },
        methods:{
            assignDefaultPaymentData(){

                if (this.agreementType && this.itemPayment && this.itemPayment.length > 0) {
                    for (let i=0; i < this.itemPayment.length; i++) {
                        if(this.itemPayment[i].type == 'monthly_lump_sum' && this[this.agreementType].includes('monthly_lump_sum')){
                            //
                        } else {
                            if(this[this.agreementType])
                                this[this.agreementType].push(this.itemPayment[i].type);
                        }

                        this.payment.types[this.itemPayment[i].type] = 1;

                        for (let prop in this.payment[this.itemPayment[i].type]){

                            let val = this.itemPayment[i][prop];
                            if(prop == 'deadline_at'){
                                val =  formatDate(this.itemPayment[i][prop], 'DD/MM/YYYY', 'unix');
                            } else if(prop == 'net_payment' || prop == 'gross_payment'){
                                val = this.itemPayment[i][prop].formatPrice();
                            } else {
                                val = this.itemPayment[i][prop];
                            }
                            this.payment[this.itemPayment[i].type][prop] = val;
                        }
                        for (let prop in this.payment.options) {
                            if(this.availableOptions.includes(prop) && this.itemPayment[i][prop] == 1) {
                                this.legal_service_order_card_options.push(prop);
                            } else {
                                this.payment.options[prop] = this.itemPayment[i][prop];
                            }
                        }
                    }

                }
            }
        }



    }
</script>