<template>
    <div>
        <b-row>
            <b-col
                    cols="12"
                    md="6"
                    class=" mb-1"
            >

                <b-table-simple class="table-details" responsive no-border-collapse>
                    <b-tbody>
                        <b-tr>
                            <b-td width="40%">{{$t('label_deal_number')}}</b-td>
                            <b-td class="actions">

                                {{editedItem.deal_number}}

                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_agreement_date')}}</b-td>
                            <b-td class="actions btn-action">
                                <b-button style="margin-bottom: -4px; margin-top: -6px;" size="sm" pill v-if="$can('edit', 'agreement.add_annex')"
                                          variant="outline-success"
                                          class="btn  ml-1 mr-1"
                                          @click="$bvModal.show(MODULE_PREFIX + '-annex-modal')">{{$t('label_add_annex')}}</b-button>
                                {{formatDate(editedItem.agreement_at, 'DD.MM.YYYY', 'unix')}}

                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_limitation_date')}}</b-td>
                            <b-td class="actions">

                                {{formatDate(editedItem.limitation_at, 'DD.MM.YYYY', 'unix')}}

                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_agreement_expiration_date')}}</b-td>
                            <b-td class="actions">

                                {{formatDate(editedItem.deadline_at, 'DD.MM.YYYY', 'YYYY-MM-DD')}}

                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_customer_type')}}</b-td>
                            <b-td class="actions">

                                {{editedItem.customer_type ? editedItem.customer_type.name : ''}}

                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_approver_firstname')}}</b-td>
                            <b-td class="actions">

                                {{editedItem.first_name}}

                                <template v-if="editedItem.changedFields && editedItem.changedFields.first_name">

                                    <feather-icon id="popover-first_name" icon="AlertCircleIcon" color="green" size="20"
                                                  class="annex-warning tooltip-html">i
                                    </feather-icon>

                                    <b-popover
                                            class="bg-info"
                                            target="popover-first_name"
                                            triggers="hover"
                                            placement="top"
                                            variant="primary"
                                    >
                                        <template #title>
                                            <span>{{$t('label_approver_firstname')}}</span>
                                        </template>
                                        <div class="tooltip-content">
                                            <div v-for="inf in editedItem.changedFields.first_name">
                                                <span class="badge badge-light-warning">{{formatDate(inf.created_at,
                                                'DD.MM.YYYY', 'unix') + ': '}}</span> {{ $t('label_changed') + ' ' +
                                                $t('label_changed_from').toLowerCase() + ' "'+inf.from_value+'" ' +
                                                $t('label_changed_to').toLowerCase() + ' "'+inf.to_value + '"'}}
                                            </div>
                                        </div>
                                    </b-popover>
                                </template>

                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_approver_lastname')}}</b-td>

                            <b-td class="actions">
                                {{editedItem.last_name}}

                                <template v-if="editedItem.changedFields && editedItem.changedFields.last_name">

                                    <feather-icon id="popover-last_name" icon="AlertCircleIcon" color="green" size="20"
                                                  class="annex-warning tooltip-html">i
                                    </feather-icon>

                                    <b-popover
                                            class="bg-info"
                                            target="popover-last_name"
                                            triggers="hover"
                                            placement="top"
                                            variant="primary"
                                    >
                                        <template #title>
                                            <span>{{$t('label_approver_lastname')}}</span>
                                        </template>
                                        <div class="tooltip-content">
                                            <div v-for="inf in editedItem.changedFields.last_name"><span class="badge badge-light-warning">{{formatDate(inf.created_at,
                                                'DD.MM.YYYY', 'unix') + ': '}}</span> {{ $t('label_changed') + ' ' +
                                                $t('label_changed_from').toLowerCase() + ' "'+inf.from_value+'" ' +
                                                $t('label_changed_to').toLowerCase() + ' "'+inf.to_value + '"'}}
                                            </div>
                                        </div>
                                    </b-popover>
                                </template>

                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_email_address')}}</b-td>

                            <b-td class="actions">
                                {{editedItem.email}}

                                <template v-if="editedItem.changedFields && editedItem.changedFields.email">

                                    <feather-icon id="popover-email" icon="AlertCircleIcon" color="green" size="20"
                                                  class="annex-warning tooltip-html">i
                                    </feather-icon>

                                    <b-popover
                                            class="bg-info"
                                            target="popover-email"
                                            triggers="hover"
                                            placement="top"
                                            variant="primary"
                                    >
                                        <template #title>
                                            <span>{{$t('label_email_address')}}</span>
                                        </template>
                                        <div class="tooltip-content">
                                            <div v-for="inf in editedItem.changedFields.email"><span class="badge badge-light-warning">{{formatDate(inf.created_at,
                                                'DD.MM.YYYY', 'unix') + ': '}}</span> {{ $t('label_changed') + ' ' +
                                                $t('label_changed_from').toLowerCase() + ' "'+inf.from_value+'" ' +
                                                $t('label_changed_to').toLowerCase() + ' "'+inf.to_value + '"'}}
                                            </div>
                                        </div>
                                    </b-popover>
                                </template>

                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_phone')}}</b-td>

                            <b-td class="actions">
                                {{editedItem.phone}}

                                <template v-if="editedItem.changedFields && editedItem.changedFields.phone">

                                    <feather-icon id="popover-phone" icon="AlertCircleIcon" color="green" size="20"
                                                  class="annex-warning tooltip-html">i
                                    </feather-icon>

                                    <b-popover
                                            class="bg-info"
                                            target="popover-phone"
                                            triggers="hover"
                                            placement="top"
                                            variant="primary"
                                    >
                                        <template #title>
                                            <span>{{$t('label_phone')}}</span>
                                        </template>
                                        <div class="tooltip-content">
                                            <div v-for="inf in editedItem.changedFields.phone"><span class="badge badge-light-warning">{{formatDate(inf.created_at,
                                                'DD.MM.YYYY', 'unix') + ': '}}</span> {{ $t('label_changed') + ' ' +
                                                $t('label_changed_from').toLowerCase() + ' "'+inf.from_value+'" ' +
                                                $t('label_changed_to').toLowerCase() + ' "'+inf.to_value + '"'}}
                                            </div>
                                        </div>
                                    </b-popover>
                                </template>

                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_pesel_number')}}</b-td>
                            <b-td class="actions">
                                {{editedItem.pesel}}
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_nip')}}</b-td>
                            <b-td class="actions">
                                {{editedItem.nip}}
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_address_from_agreement')}}</b-td>

                            <b-td class="actions">
                                {{editedItem.address_agreement}}

                                <template v-if="editedItem.changedFields && editedItem.changedFields.address_agreement">

                                    <feather-icon id="popover-address-from-agreement" icon="AlertCircleIcon" color="green" size="20"
                                                  class="annex-warning tooltip-html">i
                                    </feather-icon>

                                    <b-popover
                                            class="bg-info"
                                            target="popover-address-from-agreement"
                                            triggers="hover"
                                            placement="top"
                                            variant="primary"
                                    >
                                        <template #title>
                                            <span>{{$t('label_address_from_agreement')}}</span>
                                        </template>
                                        <div class="tooltip-content">
                                            <div v-for="inf in editedItem.changedFields.address_agreement"><span class="badge badge-light-warning">{{formatDate(inf.created_at,
                                                'DD.MM.YYYY', 'unix') + ': '}}</span> {{ $t('label_changed') + ' ' +
                                                $t('label_changed_from').toLowerCase() + ' "'+inf.from_value+'" ' +
                                                $t('label_changed_to').toLowerCase() + ' "'+inf.to_value + '"'}}
                                            </div>
                                        </div>
                                    </b-popover>
                                </template>

                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
            <b-col
                    cols="12"
                    md="6"
                    class=" mb-1"
            >
                <b-table-simple class="table-details" responsive no-border-collapse>
                    <b-tbody>

                        <b-tr>
                            <b-td width="40%">{{$t('label_correspondence_address')}}</b-td>

                            <b-td class="actions">
                                {{editedItem.address_correspondence}}

                                <template v-if="editedItem.changedFields && editedItem.changedFields.address_correspondence">

                                    <feather-icon id="popover-correspondence-address" icon="AlertCircleIcon" color="green" size="20"
                                                  class="annex-warning tooltip-html">i
                                    </feather-icon>

                                    <b-popover
                                            class="bg-info"
                                            target="popover-correspondence-address"
                                            triggers="hover"
                                            placement="top"
                                            variant="primary"
                                    >
                                        <template #title>
                                            <span>{{$t('label_correspondence_address')}}</span>
                                        </template>
                                        <div class="tooltip-content">
                                            <div v-for="inf in editedItem.changedFields.address_correspondence"><span class="badge badge-light-warning">{{formatDate(inf.created_at,
                                                'DD.MM.YYYY', 'unix') + ': '}}</span> {{ $t('label_changed') + ' ' +
                                                $t('label_changed_from').toLowerCase() + ' "'+inf.from_value+'" ' +
                                                $t('label_changed_to').toLowerCase() + ' "'+inf.to_value + '"'}}
                                            </div>
                                        </div>
                                    </b-popover>
                                </template>

                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_type_of_ageement')}}</b-td>
                            <b-td>
                                <!--<?php echo $contact_data->deal_name; ?>-->
                                {{editedItem.type? $t('label_'+editedItem.type.name_alias) : ''}}
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_subject_of_the_order')}}</b-td>

                            <b-td class="actions">
                                {{editedItem.order_subject? editedItem.order_subject.name : ''}}

                                <template v-if="editedItem.changedFields && editedItem.changedFields.order_subject">

                                    <feather-icon id="popover-order-subject" icon="AlertCircleIcon" color="green" size="20"
                                                  class="annex-warning tooltip-html">i
                                    </feather-icon>

                                    <b-popover
                                            class="bg-info"
                                            target="popover-order-subject"
                                            triggers="hover"
                                            placement="top"
                                            variant="primary"
                                    >
                                        <template #title>
                                            <span>{{$t('label_subject_of_the_order')}}</span>
                                        </template>
                                        <div class="tooltip-content">
                                            <div v-for="inf in editedItem.changedFields.order_subject"><span class="badge badge-light-warning">{{formatDate(inf.created_at,
                                                'DD.MM.YYYY', 'unix') + ': '}}</span> {{ $t('label_changed') + ' ' +
                                                $t('label_changed_from').toLowerCase() + ' "'+inf.from_value+'" ' +
                                                $t('label_changed_to').toLowerCase() + ' "'+inf.to_value + '"'}}
                                            </div>
                                        </div>
                                    </b-popover>
                                </template>

                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_agreement_description')}}</b-td>

                            <b-td class="actions">
                                {{editedItem.agreement_desc}}

                                <template v-if="editedItem.changedFields && editedItem.changedFields.agreement_desc">

                                    <feather-icon id="popover-agreement-description" icon="AlertCircleIcon" color="green" size="20"
                                                  class="annex-warning tooltip-html">i
                                    </feather-icon>

                                    <b-popover
                                            class="bg-info"
                                            target="popover-agreement-descriptiont"
                                            triggers="hover"
                                            placement="top"
                                            variant="primary"
                                    >
                                        <template #title>
                                            <span>{{$t('label_agreement_description')}}</span>
                                        </template>
                                        <div class="tooltip-content">
                                            <div v-for="inf in editedItem.changedFields.agreement_desc">{{$t('label_changed') + ' ' +
                                                $t('label_changed_from').toLowerCase() + ' "'+inf.from_value+'" ' +
                                                $t('label_changed_to').toLowerCase() + ' "'+inf.to_value + '"'}}
                                            </div>
                                        </div>
                                    </b-popover>
                                </template>

                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_deal_type')}}</b-td>
                            <b-td>
                                {{editedItem.deal_type? editedItem.deal_type.name : ''}}
                            </b-td>
                        </b-tr>

                        <b-tr>
                            <b-td width="40%">{{$t('label_consultant_leading_case')}}</b-td>
                            <b-td>
                                <router-link v-if="editedItem.consultant && $can('view', 'consultant.tab_details')"
                                             :to="{ name: 'consultant-details', params: { id: editedItem.consultant.id } }"
                                >{{editedItem.consultant? editedItem.consultant.name : ''}}</router-link>
                                <span v-else>{{editedItem.consultant? editedItem.consultant.name : ''}}</span>
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_recommended')}}</b-td>
                            <b-td>
                                <router-link v-if="editedItem.agent && $can('view', 'agent.tab_details')"
                                             :to="{ name: 'agent-details', params: { id: editedItem.agent.id } }"
                                >{{editedItem.agent? editedItem.agent.name : ''}}</router-link>
                                <span v-else>{{editedItem.agent? editedItem.agent.name : ''}}</span>
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_accountants')}}</b-td>
                            <b-td>

                                <template v-if="$can('view', 'accountant.tab_details')"
                                          v-for="accountant,ind in editedItem.accountants">
                                    <router-link :key="'acc_'+accountant.id"
                                                 :to="{ name: 'accountant-details', params: { id: accountant.id } }"
                                    >{{ accountant.name}}
                                    </router-link>
                                    {{((ind+1 == editedItem.accountants.length)? '' : ' | ')}}
                                </template>
                                <template v-else v-for="accountant,ind in editedItem.accountants">
                                    {{ accountant.name}} {{((ind+1 == editedItem.accountants.length)? '' : ' | ')}}
                                </template>
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_solicitors')}}</b-td>
                            <b-td>

                                <template v-if="$can('view', 'solicitor.tab_details')"
                                          v-for="solicitor,ind in editedItem.solicitors">
                                    <router-link :key="'sol_'+solicitor.id"
                                                 :to="{ name: 'solicitor-details', params: { id: solicitor.id } }"
                                    >{{ solicitor.name}}
                                    </router-link>
                                    {{((ind+1 == editedItem.solicitors.length)? '' : ' | ')}}
                                </template>
                                <template v-else v-for="solicitor,ind in editedItem.solicitors">
                                    {{ solicitor.name}} {{((ind+1 == editedItem.solicitors.length)? '' : ' | ')}}
                                </template>
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_secretaries')}}</b-td>
                            <b-td>

                                <template v-if="$can('view', 'secretary.tab_details')"
                                          v-for="secretary,ind in editedItem.secretaries">
                                    <router-link :key="'acc_'+secretary.id"
                                                 :to="{ name: 'secretary-details', params: { id: secretary.id } }"
                                    >{{ secretary.name}}
                                    </router-link>
                                    {{((ind+1 == editedItem.secretaries.length)? '' : ' | ')}}
                                </template>
                                <template v-else v-for="secretary,ind in editedItem.secretaries">
                                    {{ secretary.name}} {{((ind+1 == editedItem.secretaries.length)? '' : ' | ')}}
                                </template>
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_status')}}</b-td>
                            <b-td>
                                <span v-if="editedItem.agreement_status">
                                    {{$t((editedItem.agreement_status.translation_index == 'label_forward_to_a_lawyer') ? 'label_forwarded_to_a_lawyer' : editedItem.agreement_status.translation_index)}}
                                </span>


                            </b-td>
                        </b-tr>

                    </b-tbody>
                </b-table-simple>



            </b-col>
        </b-row>

        <b-row v-if="editedItem.annexes && editedItem.annexes.length > 0" clss="mt-3">
            <b-col cols="12">

                <b-table-simple  caption-top bordered small  responsive no-border-collapse>
                    <caption> <b>{{$t('label_annexes')}}</b></caption>
                    <b-thead head-variant="info">
                        <b-tr>
                            <b-th>{{$t('label_annex_adding_date')}}</b-th>
                            <b-th >{{$t('label_annex_effective_date_from')}}</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>

                        <b-tr v-for="annex in editedItem.annexes">
                            <b-td>
                                {{formatDate(annex.created_at, 'DD.MM.YYYY', 'unix')}}
                            </b-td>
                            <b-td>
                                {{formatDate(annex.effective_date_at, 'DD.MM.YYYY', 'unix')}}
                            </b-td>
                        </b-tr>

                    </b-tbody>
                </b-table-simple>
            </b-col>
        </b-row>
        <b-row clss="mt-3">
            <b-col cols="12">

                <b-table-simple caption-top bordered small responsive no-border-collapse>
                    <caption> <b>{{$t('label_payments')}}</b></caption>
                    <b-thead head-variant="info">
                        <b-tr>
                            <b-th>{{$t('label_payment_type')}}</b-th>
                            <b-th>{{$t('label_payment_amount_netto')}}</b-th>
                            <b-th>{{$t('label_payment_amount_gross')}}</b-th>
                            <b-th>{{$t('label_the_monthly_lump_sum_includes_a_limit_in_quantity').ucFirst()}}</b-th>
                            <b-th>{{$t('label_paid_in_cash')}}</b-th>
                            <b-th>{{$t('label_before_each_implementation_on_time') + ' / ' + $t('label_days')}}</b-th>
                            <b-th>{{$t('label_payment_after_the_end_of_the_month_on_time') + ' / ' + $t('label_days')}}</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>

                        <b-tr v-for="payment in editedItem.payments">
                            <b-td>{{$t(transPaymentType(payment.type))}}</b-td>
                            <b-td>{{payment.net_payment ? payment.net_payment.formatPrice() : '--'}}</b-td>
                            <b-td>{{payment.gross_payment ? payment.gross_payment.formatPrice() : '--'}}</b-td>
                            <b-td>{{payment.lump_sum_months ? payment.lump_sum_months : '--'}}</b-td>
                            <b-td>{{payment.pay_in_cash == 1 ? $t('label_yes') : '--'}}</b-td>
                            <b-td>{{payment.pay_for_each_participation == 1 ? parseInt(payment.each_participation_days) : '--'}}</b-td>
                            <b-td>{{payment.pay_after_month == 1 ? parseInt(payment.pay_after_month_days) : '--'}}</b-td>

                        </b-tr>

                    </b-tbody>
                </b-table-simple>
            </b-col>
        </b-row>
        <b-row v-if="editedItem.procurators && editedItem.procurators.length > 0" clss="mt-3">
            <b-col cols="12">

                <b-table-simple  caption-top bordered small  responsive no-border-collapse>
                    <caption> <b>{{$t('label_procurators')}}</b></caption>
                    <b-thead head-variant="info">
                        <b-tr>
                            <b-th>{{$t('label_name_and_surname')}}</b-th>
                            <b-th>{{$t('label_function')}}</b-th>
                            <b-th>{{$t('label_email')}}</b-th>
                            <b-th>{{$t('label_phone')}}</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>

                        <b-tr v-for="procurator in editedItem.procurators">
                            <b-td>{{procurator.initials}}</b-td>
                            <b-td>{{procurator.function}}</b-td>
                            <b-td>{{procurator.email}}</b-td>
                            <b-td>{{procurator.phone}}</b-td>
                        </b-tr>

                    </b-tbody>
                </b-table-simple>
            </b-col>
        </b-row>
        <br>
        <annex-modal @item-added="$emit('annex-added', $event)" :is-deal="is_deal" :edited-item="editedItem" :order-subjects="order_subjects"></annex-modal>
    </div>
</template>

<script>
    import {
        BPopover,
        BTableSimple, BTbody, BTr, BTd, BButton,BThead, BTh

    } from 'bootstrap-vue'

    import annexModal from './../modals/annexModal'
    import {  AGREEMENT_PREFIX, DEAL_PREFIX, transPaymentType} from './../moduleHelper'
    export default {
        components: {
            BPopover,
            BTableSimple, BTbody, BTr, BTd, BButton,BThead, BTh,
            annexModal
        },

        props: ['editedItem', 'isDeal'],
        data() {
            return {
                AGREEMENT_PREFIX,
                DEAL_PREFIX,
                MODULE_PREFIX: '',
                // AGREEMENT_DYNAMIC_STORE_NAME,
                is_deal: false,
                transPaymentType,

                order_subjects:[]
            }
        },
        methods:{
            getPageData(){
                this.async('get', '/select_options', {params:{options:['order_subject']}}, function(resp){
                    this.order_subjects = resp.data.order_subject;
                });
            }
        },
        created() {

            this.is_deal = this.$router.currentRoute.path.includes('/' + this.DEAL_PREFIX + '/');
            this.MODULE_PREFIX = this.is_deal ? this.DEAL_PREFIX : this.AGREEMENT_PREFIX;
            this.getPageData();
        },

    }
</script>