<template>

    <b-modal
            :id="MODULE_PREFIX + '-annex-modal'"
            hide-footer
            size="lg"
            @hide="resetForm()"
            @shown="initData()"
    >
        <template #modal-title>
            {{$t('label_add_annex')}}
        </template>
        <template #default="{ hide }">
            <!-- Form -->
            <validation-observer
                    #default="{ handleSubmit, invalid }"
                    :ref="MODULE_PREFIX + '_FORM'"
                    tag="form"
                    class="p-1"
            >

                <validation-provider

                        #default="validationProps"
                        :name="$t('label_annex_effective_date_from')"
                        rules="required|date-gt-current:DD/MM/YYYY"
                        class="mr-1 flex-grow-1"
                        slim
                >
                    <b-form-group
                            :label="$t('label_annex_effective_date_from')"
                            label-for="meeting_type_id"
                    >

                        <custom-date-picker
                                :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                                :locale="$store.state.appConfig.locale"
                                :placeholder="$t('label_DD-MM-YYYY')"
                                :value="itemData.effective_date_at"

                                @input="itemData.effective_date_at = $event"
                        >
                            <template #clear-btn="{ vm }">
                                <feather-icon icon="XIcon" size="14"/>
                            </template>
                            <template #label>
                                <feather-icon title="Clear" data-toggle icon="CalendarIcon" size="18"/>
                            </template>
                        </custom-date-picker>

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>


                <payment-block :is-annex="true"
                               :item-payment="item_payment"
                               @paymentDataUpdated="itemData.payment = $event; "
                               :agreement-type="agreement_type_alias"
                ></payment-block>
                <!--:agreement-type="agreementType"-->

                <validation-provider
                        #default="validationProps"
                        :name="$t('label_first_name')"
                        rules="required"
                >
                    <b-form-group
                            :label="$t('label_first_name')"
                            label-for="first_name"
                    >
                        <b-form-input
                                id="first_name"
                                v-model="itemData.first_name"
                                :state="getValidationState(validationProps)"
                                :placeholder="$t('label_first_name')"
                        />

                        <b-form-invalid-feedback>
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>
                <validation-provider
                        #default="validationProps"
                        :name="$t('label_last_name')"
                        rules="required"
                >
                    <b-form-group
                            :label="$t('label_last_name')"
                            label-for="last_name"
                    >
                        <b-form-input
                                id="last_name"
                                v-model="itemData.last_name"
                                :placeholder="$t('label_last_name')"
                                :state="getValidationState(validationProps)"
                                trim
                        />

                        <b-form-invalid-feedback>
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>


                <validation-provider

                        #default="validationProps"
                        :name="$t('label_address_from_agreement')"
                        rules=""
                        slim
                >
                    <b-form-group
                            :label="$t('label_address_from_agreement')"
                    >
                        <b-form-textarea
                                :state="getValidationState(validationProps)"
                                v-model="itemData.address_agreement"
                        ></b-form-textarea>

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider

                        #default="validationProps"
                        :name="$t('label_correspondence_address')"
                        rules=""
                        slim
                >
                    <b-form-group
                            :label="$t('label_correspondence_address')"

                    >
                        <b-form-textarea

                                :state="getValidationState(validationProps)"
                                v-model="itemData.address_correspondence"
                        ></b-form-textarea>

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider
                        #default="validationProps"
                        :name="$t('label_email_address')"
                        rules="required|email"
                >
                    <b-form-group
                            :label="$t('label_email_address')"
                            label-for="email"
                    >
                        <b-form-input
                                id="email"
                                v-model="itemData.email"
                                :state="getValidationState(validationProps)"
                                trim
                        />

                        <b-form-invalid-feedback>
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider
                        #default="validationProps"
                        :name="$t('label_phone')"
                        rules=""
                >
                    <b-form-group
                            :label="$t('label_phone')"
                            label-for="phone"
                    >
                        <b-form-input
                                id="phone"
                                v-model="itemData.phone"
                                :state="getValidationState(validationProps)"
                                trim
                        />

                        <b-form-invalid-feedback>
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>
                <validation-provider
                        #default="validationProps"
                        :name="$t('label_subject_of_the_order')"
                        rules="required"
                >
                    <b-form-group
                            :label="$t('label_subject_of_the_order')"
                            label-for="order_subject_id"
                            :state="getValidationState(validationProps)"
                    >
                        <v-select
                                input-id="order_subject_id"
                                v-model="itemData.order_subject_id"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="orderSubjects"
                                :reduce="val => val.id"
                                label="name"
                                :clearable="false"
                                :placeholder="$t('label_select')"

                        >
                            <template v-slot:option="option">
                                <span class="">{{ option.name }}</span>
                            </template>
                            <template v-slot:selected-option="option">
                                <!--<span :class="option.icon"></span>-->
                                <span class="">{{ option.name }}</span>
                            </template>

                        </v-select>
                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider
                        class="mt-2"
                        #default="validationProps"
                        :name="$t('label_to_ocr')"
                        slim
                >
                    <b-form-group
                            label=""
                            label-for="meeting_type_id"
                    >
                        <!--:checked="itemData.google_sync"-->
                        <b-form-checkbox
                                v-model="itemData.ocr"
id="annex-ocr"
                        >
                            <label for="annex-ocr">{{ $t('label_to_ocr') }}</label>
                        </b-form-checkbox>
                    </b-form-group>
                </validation-provider>

                <validation-provider
                        class="mt-2"
                        :name="$t('label_document')"
                        slim
                        #default="validationProps"

                >

                    <file-input

                            v-model="documents"
                            class="file--uploader drop-block mb-3"
                            :max-files-count='10'
                            :required="true"
                            allowed-extensions="*"
                            :max-file-size='15'
                            :url="''"
                    >
                        <template #uploadBody>
                            <feather-icon class="mr-1" data-toggle icon="DownloadCloudIcon" size="24"/>
                            <!--<img :src="require('@/assets/images/upload.svg')" class="mr-1">-->
                            {{$t('label_add_file')}}
                        </template>

                        <template #errorBug="fileErrors">

                            <span v-if="validationProps.failedRules.length > 0 || fileErrors.errors.length > 0">{{validationProps.failedRules.length  > 0 ?  validationProps.errors[0] : fileErrors.errors[0] }}</span>
                            <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </template>
                    </file-input>


                </validation-provider>
                <b-progress class="progress-form-control" v-if="percentsHttpRequestDone" :value="percentsHttpRequestDone" max="100" show-progress animated></b-progress>
                <div class="d-flex justify-content-end mt-2">

                    <button class=" btn btn-default" @click.prevent="$bvModal.hide(MODULE_PREFIX + '-annex-modal')">
                        {{$t('label_close')}}
                    </button>

                    <button type="submit" @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)"
                            class="btn btn-primary waves-effect waves-light">{{(action == 'editing')?$t('label_edit'):$t('label_add')}}
                    </button>
                </div>
            </validation-observer>


        </template>
    </b-modal>
</template>

<script>
    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import fileInput from '@/views/components/fileUploadInput'

    import {serialize} from 'object-to-formdata';

    import {
        BFormTextarea,
        BFormGroup, BFormInput, BFormInvalidFeedback, BInputGroupPrepend, BInputGroupAppend, BInputGroup, BFormCheckbox, BProgress

    } from 'bootstrap-vue'
    import vSelect from 'vue-select'

    import paymentBlock from './../includes/paymentBlock'

    import {AGREEMENT_PREFIX, DEAL_PREFIX} from './../moduleHelper'

    export default {
        components: {

            BFormGroup,
            BFormInput,
            BFormInvalidFeedback,
            BFormTextarea,
            BInputGroup,
            BInputGroupPrepend,
            BInputGroupAppend,
            BFormCheckbox,
            BProgress,
            ValidationProvider,
            ValidationObserver,
            vSelect,
            paymentBlock,
            fileInput
        },
        props: ['editedItem', 'orderSubjects', 'isDeal'],
        data() {
            return {
                AGREEMENT_PREFIX,
                DEAL_PREFIX,
                MODULE_PREFIX: '',

                action: 'adding',

                blankItemData: {
                    agreement_id: '',
                    order_subject_id: null,
                    address_correspondence: '',
                    phone: '',
                    email: '',
                    address_agreement: '',
                    first_name: '',
                    last_name: '',
                    payment: {},
                },
                ocr: '',
                documents: [],

                itemData: {},

                item_payment: false,
                agreement_type_alias: ''

            }
        },
        methods: {
            initData() {
                this.item_payment = this.editedItem.payments;

                this.agreement_type_alias = this.editedItem.type?  this.editedItem.type.name_alias : '';

                let itemData = Object.assign({}, this.blankItemData);
                itemData.agreement_id = this.is_deal? this.editedItem.agreement_id : this.editedItem.id;

                for (let prop in itemData) {
                    if (this.editedItem.hasOwnProperty(prop)) {
                        itemData[prop] = this.editedItem[prop];
                    }
                }
                itemData.payment = {};


                this.itemData = itemData;

            },
            resetForm() {
                this.itemData = Object.assign({},this.blankItemData);
                this.item_payment = false;
                this.$refs[this.MODULE_PREFIX + '_FORM'].reset();
            },
            onSubmit() {
                for(let payType in this.itemData.payment){
                    if(typeof this.itemData.payment[payType] == 'object')
                        for(let payProp in  this.itemData.payment[payType]) {
                            if(payProp == 'net_payment' || payProp == 'gross_payment'){
                                this.itemData.payment[payType][payProp] = this.itemData.payment[payType][payProp].formatPriceToNumber();
                            }
                        }
                }
                let formData = new FormData();

                formData = serialize(this.itemData, {}, formData);
                formData.append('ocr', this.ocr);
                if (this.documents.length > 0) {
                    formData.append('annex_document[]', this.documents[0]);
                }

                this.async('post', '/'+this.AGREEMENT_PREFIX+'_annexes', formData , function(res){
                    this.$emit('item-added', {});
                    this.$bvModal.hide(this.MODULE_PREFIX + '-annex-modal');
                }, false, {headers:{'Content-Type': 'multipart/form-data'}, showProgress:true});

            }
        },
        created(){
            this.MODULE_PREFIX = this.isDeal?  this.DEAL_PREFIX : this.AGREEMENT_PREFIX;
        }


    }
</script>