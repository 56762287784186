
export const CONTRACT_REG_PREFIX = 'contractregistry'
export const AGREEMENT_PREFIX = 'agreement'
export const NOTE_PREFIX = 'note'
export const SMS_PREFIX = 'sms'
export const RODO_PREFIX = 'rodo'
export const TASK_PREFIX = 'task'
export const DOCUMENT_PREFIX = 'document'
export const DUE_LAW_PREFIX = 'due_law'
export const CLIENT_PAYMENT_PREFIX = 'client_payment'
export const PAY_CORRECTION_PREFIX = 'pay_correction'
export const PROCURATOR_PREFIX = 'procurator'
export const DEAL_PREFIX = 'deal'
export const PAYMENT_PREFIX = 'payment'
export const HISTORY_PREFIX = 'history'
export const RECEIVABLES_PREFIX = 'receivables'



export const docTypes = [
    {label: 'label_deal_folder', alias: 'default'},
    {label: 'label_correspondence', alias: 'correspondence'}
];
export const payReportTypes = [
    {label: 'label_lump_sums_and_advance_payment', alias: 'default'},
    {label: 'label_reports_to_be_released', alias: 'reports_to_calculate'},
    {label: 'label_reports_to_be_settled', alias: 'calculated_reports'}
];
export const taskTypes = [
    {label: 'label_actual_tasks', alias: 'actual_tasks'},
    {label: 'label_this_week', alias: 'this_week'},
    {label: 'label_this_month', alias: 'this_month'},
    {label: 'label_all_tasks', alias: 'all_tasks'},
    {label: 'label_tasks_done_in_time', alias: 'tasks_in_time'},
    {label: 'label_tasks_havet_done_in_time', alias: 'tasks_not_in_time'},
];
export const paymentTypes = [
    {
        'alias': 'preparatory_activities',
        'agrm_types': 'provision_legal_services',
        'trans_index': 'label_preparatory_activities',
        'name': 'Czynności przygotowawcze'
    },
    {
        'alias': 'flat_rate_reward',
        'agrm_types': 'provision_legal_services',
        'trans_index': 'label_flat_rate_reward',
        'name': 'Wynagrodzenie ryczałtowe'
    },
    {
        'alias': 'success_fee',
        'agrm_types': 'provision_legal_services|provision_redress_services',
        'trans_index': 'label_success_fee',
        'name': 'Success fee'
    },
    {
        'alias': 'preparation_fee',
        'agrm_types': 'provision_redress_services',
        'trans_index': 'label_preparation_fee',
        'name': 'Opłata przygotowawcza'
    },
    {
        'alias': 'single_for_meeting',
        'agrm_types': 'legal_service_order_card',
        'trans_index': 'label_once_for_meeting',
        'name': 'Jednorazowo za spotkanie'
    },
    {
        'alias': 'preparatory_payment',
        'agrm_types': 'legal_service_order_card',
        'trans_index': 'label_preparatory',
        'name': 'Przygotowawcze'
    },
    {
        'alias': 'hourly_payment',
        'agrm_types': 'legal_service_order_card',
        'trans_index': 'label_hourly_billing',
        'name': 'Rozliczenie godzinowe'
    },
    {
        'alias': 'monthly_lump_sum',
        'agrm_types': 'legal_service_order_card',
        'trans_index': 'label_monthly_lump_sum',
        'name': ' Ryczałt miesięczny'
    },
    {
        'alias': 'each_participation_activity',
        'agrm_types': 'legal_service_order_card',
        'trans_index': 'label_for_each_participation_in_the_activity',
        'name': 'Za każdy udział w czynności'
    }, //additional pay
];
export const paymentStatuses = [
    {
        'alias': 'paid',
        'label': 'label_paid',
    },
    {
        'alias': 'not_paid',
        'label': 'label_not_paid',
    },
];
export function transPaymentType(type)
{

    for(let i =0; i<paymentTypes.length; i++) {
    if (paymentTypes[i]['alias'] == type) {
       return paymentTypes[i]['trans_index'];
        break;
    }
}

    return '';
}

export function getAgrmPaymentTypes(agrm_alias)
{
    if(!agrm_alias) return paymentTypes;

    let types = [];

    for (let i = 0; i< paymentTypes.length; i++) {
        let agrTypes = paymentTypes[i]['agrm_types'].split('|');
    if (agrTypes.includes(agrm_alias)) {
        // paymentTypes[i]['translation'] = $t(paymentTypes[i]['trans_index']);
        types.push(paymentTypes[i]);
    }

}
    return types;
}
export function resolveAgreementStatusVariant(status_alias) {

    if (status_alias == 'generated_agreement') return 'info'
    else if (status_alias == 'awaiting_signature') return 'warning'
    else if (status_alias == 'agreement_paid') return 'info'
    else if (status_alias == 'agreement_signed') return 'secondary'
    else if (status_alias == 'completing_agreement') return 'success'
    else if (status_alias == 'forward_to_lawyer') return 'primary'
    return 'primary' // new
}

export function resolveMeetingStatusVariant(status) {

    if (status == '114') return 'info' // videokonferennce
    if (status == '115') return 'success' // live
    // if (status == '111') return 'danger' // rejected
    return 'primary' // new
}

export function resolveDeadlineText(name_alias) {
    let text = '';


    switch (name_alias) {
        case 'every_day':
            text = 'label_every_day';
            break;
        case 'every_week':
            text = 'label_every_week';
            break;
        case 'every_month':
            text = 'label_every_mounth';
            break;
        case 'every_year':
            text = 'label_every_year';
            break;
    }
    return text;
}

export function calcCommonNetSum(rows, field) {
    let sum = 0;
    if (rows.length > 0) {
        for (let i = 0; i < rows.length; i++) {
            sum += parseFloat(rows[i][field]);
        }
    }
    return sum;
}

export function resolveUsersText(related_user_ids, relatedUsers) {
    let html = '';
    if (related_user_ids) {
        let ids = related_user_ids.split(',');
        for (let i = 0; i < ids.length; i++) {
            for (let key in relatedUsers) {
                if (relatedUsers[key].id == ids[i]) {
                    html += relatedUsers[key].name + ' '; //&nbsp;/
                }
            }

        }
        html = html.substr(0, html.length - 2);
    }

    return html;
}

