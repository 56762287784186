<template>

    <b-modal
            :id="PREFIX + '-modal'"
            hide-footer
            @hide="resetForm()"
            @shown="initData()"


    >
        <template #modal-title>
            {{$t('label_add_the_amount_due_to_the_law_firm')}}
        </template>
        <template #default="{ hide }">



        <validation-observer
                tag="form"
                class="p-1"
                #default="{handleSubmit, reset, invalid}"
                :ref="PREFIX + '_FORM'"
        >

                <validation-provider
                        #default="validationProps"
                        :name="$t('label_payment_amount_netto')"
                        rules="required|price"
                        slim
                >
                    <b-form-group
                            :label="$t('label_payment_amount_netto')"
                    >
                        <b-form-input
                                class="price-input"
                                required
                                v-model="itemData.net_payment"
                                :state="getValidationState(validationProps)"
                                :placeholder="$t('label_payment_amount_netto')"
                        />

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>


            <div class="d-flex justify-content-end mt-2">
                <button @click.prevent="$bvModal.hide(PREFIX + '-modal')" type="reset" class=" btn btn-default">{{$t('label_close')}}</button>

                <button @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)" type="submit"
                        class="btn btn-primary waves-effect waves-light">{{(action == 'editing')?$t('label_save'):$t('label_add')}}</button>
            </div>

        </validation-observer>

        </template>

    </b-modal>
</template>


<script>
    import { ValidationProvider, ValidationObserver} from 'vee-validate'

    import {
         BFormTextarea,
         BFormGroup, BFormInput, BFormInvalidFeedback, BInputGroupAppend, BInputGroup, BFormCheckbox, BInputGroupPrepend,
    } from 'bootstrap-vue'
    import  fileInput from '@/views/components/fileUploadInput'
    import vSelect from 'vue-select'

    import {AGREEMENT_PREFIX, DEAL_PREFIX, PAYMENT_PREFIX,  PAY_CORRECTION_PREFIX as PREFIX} from './../moduleHelper'

    export default {
        components: {

             BFormGroup, BFormInput, BFormInvalidFeedback, BFormTextarea, BInputGroup, BInputGroupAppend, BFormCheckbox, BInputGroupPrepend,

            ValidationProvider,
            ValidationObserver,
            fileInput,
            vSelect
        },
        props:['moduleItem', 'correctionForId', 'isDeal'],
        data() {
            return {
                AGREEMENT_PREFIX,
                DEAL_PREFIX,
                PAYMENT_PREFIX,
                MODULE_PREFIX:'',
                PREFIX,
                action: 'adding',

                blankItemData: {

                    net_payment: '',

                },

                itemData: {},

            }
        },

        methods: {
            initData(){
                let item = Object.assign({},this.editedItem);

                item.payment_id = this.correctionForId;
                if(this.editedItem){
                    this.action = 'editing';

                    this.itemData = item ;
                } else {
                    this.action = 'adding';

                    this.itemData = item;

                }
            },
            resetForm() {
                this.itemData = Object.assign({},this.blankItemData);

                this.$refs[this.PREFIX + '_FORM'].reset();
            },
            onSubmit(){
                this.itemData.net_payment = this.itemData.net_payment.formatPriceToNumber();
                if(this.action == 'adding') {
                    this.async('post', '/' + this.AGREEMENT_PREFIX +'_'+this.PAYMENT_PREFIX+'_corrections', this.itemData, function(resp){ //this.itemData.contact_id
                        this.$emit('item-added',{});
                        this.$bvModal.hide(this.PREFIX + '-modal');
                    });
                }

            }
        },
        created(){
            this.MODULE_PREFIX = this.isDeal ? this.DEAL_PREFIX : this.AGREEMENT_PREFIX;
        }

    }
</script>